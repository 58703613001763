import GraphemeSplitter from 'graphemer'
import React from 'react'
import { config } from '../../../config'
import { Radium } from '../hocs/Radium'
import { theme } from '../styles/theme'
import { Text } from './Text'
import { Username } from './Username'
import { View } from './View'

const splitter = new GraphemeSplitter()

class ReadMoreComponent extends React.Component {
  constructor(props) {
    super(props)

    const { children, maxLength = config.components.ReadMore.maxLength } = props

    const text = children
      ? children.replace(
          /\n/g,
          ' '.repeat(config.components.ReadMore.lineBreakWidth)
        )
      : ''

    const hasPreview =
      typeof children === 'string' &&
      text.length > maxLength &&
      splitter.countGraphemes(text) > maxLength

    this.state = {
      preview: hasPreview ? this._getPreview() : undefined,
      showAllText: false,
    }
  }

  _getPreview = () => {
    const { children, maxLength = config.components.ReadMore.maxLength } =
      this.props

    const chars = splitter.splitGraphemes(children)

    let charCount = 0

    for (let index = 0; index <= chars.length; index++) {
      charCount =
        chars[index] === '\n'
          ? charCount + config.components.ReadMore.lineBreakWidth
          : charCount + 1

      if (charCount > maxLength) {
        return `${chars.slice(0, index + 1).join('')}...`
      }
    }

    return children
  }

  render() {
    const { preview, showAllText } = this.state

    const { children, user, onPressUsername, ...props } = this.props

    if (children === '') {
      return null
    }

    return (
      <View>
        <Text {...props}>
          {user && user.username && (
            <Username
              bold
              hasVerifiedIcon={false}
              isSignature
              onPress={onPressUsername}
              user={user}
            />
          )}
          {!showAllText && preview ? preview : children}
        </Text>
        {preview && (
          <Text
            type="small"
            color={theme.colors.placeholder}
            onPress={(e) => {
              e.stopPropagation()
              this.setState({ showAllText: !showAllText })
            }}
            style={{
              paddingTop: 4,
              paddingRight: 8,
              paddingBottom: 4,
              paddingLeft: 0,
            }}>
            {!showAllText ? 'show more' : 'show less'}
          </Text>
        )}
      </View>
    )
  }
}

export const ReadMore = Radium(ReadMoreComponent)
