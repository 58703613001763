import { headerModes } from '@tellonym/core/artificialTells/constants'
import { getDump } from '@tellonym/core/helpers'
import { langDetectObjectsByType1, langs } from '@tellonym/enums/lib/Language'
import { PUSH_NOTIFICATION_TYPE } from '@tellonym/enums/lib/Notification'
import { ARTIFICIAL_TELL_USER_GROUP_TYPE } from '@tellonym/enums/lib/Tell'
import qs from 'qs'
import {
  append,
  assoc,
  compose,
  filter,
  identity,
  ifElse,
  is,
  mergeAll,
  mergeDeepRight,
  nth,
  prop,
  reduce,
  values,
} from 'ramda'
import { history } from './history'
import { moment } from './locales/moment'
import * as _ from './underscore'

export const getRandomNumber = (max = 80) =>
  Math.floor(Math.random() * Math.floor(max))

export const languageEnumAsString = langs.reduce((acc, curr) => {
  const enumNumber = curr.DETECTABLE_LANGUAGE

  return enumNumber
    ? {
        ...acc,
        [curr.DETECTABLE_LANGUAGE]: curr['1'],
      }
    : acc
}, {})

export const isObject = (obj) => !!obj && obj.constructor === Object

export const flattenObject = (object) =>
  Object.keys(object).reduce(
    (acc, curr) =>
      isObject(object[curr])
        ? { ...acc, ...flattenObject(object[curr]) }
        : { ...acc, [curr]: object[curr] },
    {}
  )

export const getMergedReducerModules = (defaultReducer, subReducers) => {
  const subReducer = nth(0, subReducers)
  const subReducerName = prop('name', subReducer)

  const initialState = assoc(
    subReducerName,
    subReducer.initialState,
    defaultReducer.initialState
  )

  return {
    initialState,
    reducer: (state = initialState, action) => {
      const newStateAfterDefaultReducer = defaultReducer.reducer(state, action)
      const newStateAfterSubReducer = subReducer.reducer(
        newStateAfterDefaultReducer[subReducerName],
        action
      )

      return mergeDeepRight(newStateAfterDefaultReducer, {
        [subReducer.name]: newStateAfterSubReducer,
      })
    },
  }
}

export const getMergedStyles = ifElse(is(Array), mergeAll, identity)

export const getStringsFromEnums = compose(filter(is(String)), values)

export const getNumbersFromEnums = compose(filter(is(Number)), values)

export const mapEnumToSelector = (enumObject) =>
  Object.values(enumObject).reduce((acc, value) => {
    if (typeof value === 'string') {
      acc.push({ value: enumObject[value], label: value })
    }

    return acc
  }, [])

const preventDefault = (event) => {
  const e = event || window.event
  if (e.preventDefault) {
    e.preventDefault()
  }
  e.returnValue = false
}

const onScroll = (e) => preventDefault(e)

export const disableScrolling = () => {
  document.addEventListener('touchmove', preventDefault, false)
  window.addEventListener('DOMMouseScroll', onScroll, false)
  window.onmousewheel = onScroll
  document.onmousewheel = onScroll
}

export const enableScrolling = () => {
  document.removeEventListener('touchmove', preventDefault, false)
  window.removeEventListener('DOMMouseScroll', onScroll, false)
  window.onmousewheel = null
  document.onmousewheel = null
}

export const formatToDate = (date) => moment(date).format('YYYY-MM-DD')

export const orNull = (value) => value || null

export const formatTimestamp = (t) => {
  const time = moment(t)
  return time.isValid() ? time.format('YYYY-MM-DD HH:mm:ss Z') : '-'
}

export const propSynonymMap = {
  createdAt: ['createdAt', 'Timestamp', formatTimestamp],
  firstSeen: ['firstSeen', 'First Seen', formatTimestamp],
  lastActive: ['lastActive', 'Last Active', formatTimestamp],
  lastActiveApp: ['lastActiveApp', 'Last Active App', formatTimestamp],
  lastActiveWeb: ['lastActiveWeb', 'Last Active Web', formatTimestamp],
  lastIpAddress: ['lastIpAddress', 'Last Used IP'],
  lastIp: ['lastIp', 'Last IP'],
  lastSeen: ['lastSeen', 'Last Seen', formatTimestamp],
  ownerUsername: ['ownerUsername', 'Owner'],
  postContent: ['postContent', 'Answer'],
  postCreatedAt: ['postCreatedAt', 'Answer Timestamp', formatTimestamp],
  receiverUsername: ['receiverUsername', 'Receiver'],
  registered: ['registered', 'Registered', formatTimestamp],
  senderIp: ['senderIp', 'Sender IP'],
  senderUsername: ['senderUsername', 'Sender'],
  tellContent: ['tellContent', 'Tell'],
  tellCreatedAt: ['tellCreatedAt', 'Tell Timestamp', formatTimestamp],
}

export const getAnswerDump = getDump([
  propSynonymMap.tellContent,
  propSynonymMap.postContent,
  propSynonymMap.senderIp,
  propSynonymMap.tellCreatedAt,
  propSynonymMap.postCreatedAt,
  propSynonymMap.senderUsername,
])

export const getCommentDump = getDump([
  ['content', 'Comment'],
  propSynonymMap.createdAt,
])

export const getDeviceDump = getDump([
  propSynonymMap.ownerUsername,
  'clientVersion',
  'device',
  propSynonymMap.firstSeen,
  'isDeviceLoggedIn',
  propSynonymMap.lastIp,
  propSynonymMap.lastSeen,
  'name',
  'os',
  'userAgent',
])

export const getProfileDump = getDump([
  'username',
  'email',
  'twitter',
  'instagram',
  'phonePrefix',
  'phoneNumber',
  propSynonymMap.lastActive,
  propSynonymMap.registered,
  propSynonymMap.lastIpAddress,
])

export const getSentTellDump = getDump([
  propSynonymMap.tellContent,
  propSynonymMap.postContent,
  propSynonymMap.tellCreatedAt,
  propSynonymMap.postCreatedAt,
  propSynonymMap.senderUsername,
])

export const getTellDump = getDump([
  propSynonymMap.tellContent,
  propSynonymMap.senderIp,
  propSynonymMap.tellCreatedAt,
  propSynonymMap.senderUsername,
])

/**
 * @param requiredPermission {string} the permission that should be checked e.g. `user.profile.edit`
 * @param permissions {Array<string>} list of all given permissions
 * @param allowGlobalWildcard {boolean} sets whether * is allowed to match the requiredPermission
 */
export const checkPermission = (
  requiredPermission,
  permissions,
  allowGlobalWildcard = true
) => {
  const isPermissionExistend = (permissionToCheck) =>
    permissions.includes(permissionToCheck)

  if (isPermissionExistend('*') && allowGlobalWildcard) {
    return true
  }

  const splittedPermissionPath = requiredPermission.split('.')

  const { hasPermission } = splittedPermissionPath.reduce(
    (acc, curr) => {
      if (acc.hasPermission) {
        return acc
      }

      const currentPermissionPath = [...acc.permissionPath, curr]
      const isLastItem =
        currentPermissionPath.length === splittedPermissionPath.length
      const currentPermission = [
        ...currentPermissionPath,
        ...(isLastItem ? [] : ['*']),
      ].join('.')

      const hasCurrentPermission = isLastItem
        ? isPermissionExistend(currentPermission) ||
          permissions.findIndex((p) => p === currentPermission) > -1
        : isPermissionExistend(currentPermission)

      if (hasCurrentPermission) {
        return { ...acc, hasPermission: true }
      }

      return { ...acc, permissionPath: currentPermissionPath }
    },
    { hasPermission: false, permissionPath: [] }
  )

  return hasPermission
}

export const getAvailablePushNotificationStringsWithConfig = () => {
  // available and tested push notifications
  const {
    GOT_TELL,
    GOT_ANSWER,
    GOT_LIKED,
    GOT_NEW_SUBSCRIPTION,
    GOT_NEW_PUBLIC_SUBSCRIPTION,
    GOT_PUBLIC_TELL,
    FRIEND_ANSWERED_MESSAGE,
    CONTACT_BOOK_JOINED,
    SHARE_LINK,
    CUSTOM_TEXT,
    GOT_REQUESTED_TELL,
    CREATE_REQUEST_TELL,
    POST_REQUEST_TELL_EXPIRED,
    FOLLOW_NOTIFICATION_ANSWER_CREATED,
    USER_TAGGED,
    STATUS_EMOJI_ACHIEVED,
    GOT_MATCHED,
  } = PUSH_NOTIFICATION_TYPE

  const types = reduce(
    (acc, curr) => append(PUSH_NOTIFICATION_TYPE[curr], acc),
    [],
    [
      GOT_TELL,
      GOT_ANSWER,
      GOT_LIKED,
      GOT_NEW_SUBSCRIPTION,
      GOT_NEW_PUBLIC_SUBSCRIPTION,
      GOT_PUBLIC_TELL,
      FRIEND_ANSWERED_MESSAGE,
      CONTACT_BOOK_JOINED,
      SHARE_LINK,
      CUSTOM_TEXT,
      GOT_REQUESTED_TELL,
      CREATE_REQUEST_TELL,
      POST_REQUEST_TELL_EXPIRED,
      FOLLOW_NOTIFICATION_ANSWER_CREATED,
      USER_TAGGED,
      STATUS_EMOJI_ACHIEVED,
      GOT_MATCHED,
    ]
  )

  const config = {
    [GOT_ANSWER]: ['targetId', 'username', 'text'],
    [GOT_LIKED]: ['targetId', 'username', 'text'],
    [GOT_PUBLIC_TELL]: ['username'],
    [FRIEND_ANSWERED_MESSAGE]: ['username'],
    [CONTACT_BOOK_JOINED]: ['username'],
    [CUSTOM_TEXT]: ['title', 'body'],
    [GOT_REQUESTED_TELL]: ['targetId', 'username', 'text'],
    [CREATE_REQUEST_TELL]: ['targetId', 'text'],
    [POST_REQUEST_TELL_EXPIRED]: ['targetId', 'text'],
    [FOLLOW_NOTIFICATION_ANSWER_CREATED]: ['username'],
    [USER_TAGGED]: ['targetId', 'username'],
  }

  return { types, config }
}

export const queryElementHeight = (selector) => {
  const el = document.querySelector(selector)

  if (!el) {
    return 0
  }

  return parseInt(window.getComputedStyle(el)?.height ?? '0', 10)
}

/**
 * Made for antd Select options, transforms string array to label/value object array
 * @param {Array<String>} array The array to be converted
 * @returns {Array<{label: String, value: String}>} The converted array
 */
export const convertArrayToOptions = (array) =>
  array.map((value) => ({ label: value, value }))

/**
 * Made for antd Select options, transforms object to label/value object array where key
 * and label are the values of the object
 * @param {[key as string]: any} object The object to be converted
 * @returns {Array<{label: String, value: String}>} The converted array
 */
export const convertObjectToOptions = (object) =>
  Object.values(object).map((value) => ({ label: value, value }))

/**
 * Updates the current location search with the given payload.
 * Keeps old values if they are not overwritten.
 * @param {object} payload
 */
export const updateLocationSearch = (payload) => {
  const query = qs.parse(history.location.search, { ignoreQueryPrefix: true })

  history.replace({
    ...history.location,
    search: `?${qs.stringify({ ...query, ...payload })}`,
  })
}

export const getArtificialTellsDataModeFromUrl = () => {
  const query = qs.parse(history.location.search, { ignoreQueryPrefix: true })

  const dataMode = query.dataMode
    ? parseInt(query.dataMode, 10)
    : ARTIFICIAL_TELL_USER_GROUP_TYPE.HIGH

  return dataMode
}

export const getArtificialTellsHeaderModeFromUrl = () => {
  const query = qs.parse(history.location.search, { ignoreQueryPrefix: true })

  return query.headerMode ?? headerModes.STATS
}

export const getArtificialTellsLanguageFromUrl = () => {
  const [, , language = 'en'] = history.location.pathname.split('/')
  return langDetectObjectsByType1[language] ? language : 'en'
}

export const getArtificialTellsLanguage = () =>
  _.getState((state) => state.artificialTellsV2.language) ??
  getArtificialTellsLanguageFromUrl()

export const fixTypeCasing = (type) =>
  type
    ?.split('_')
    .filter((w) => w !== '_')
    .map((w) => `${w[0]}${w.slice(1).toLowerCase()}`)
    .join(' ') ?? ''
