import { WARNING_REASON } from '@tellonym/enums/lib/User'
import { is } from 'ramda'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Button,
  Dropdown,
  helpers,
  Input,
  Modal,
  Text,
  theme,
  View,
} from '../../common'
import { createWarning } from '../actions'

const banReasons = helpers.getStringsFromEnums(WARNING_REASON)

const CreateWarning = connect(null, (dispatch) => ({
  actions: bindActionCreators({ createWarning }, dispatch),
}))(({ actions, modalId, userId }) => {
  const [warningMessage, setWarningMessage] = useState('')
  const [warningMessageInternal, setWarningMessageInternal] = useState('')
  const [warningReason, setWarningReason] = useState()
  const isValidWarning = is(String, warningReason) && is(String, warningMessage)

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Modal.Body>
        <View
          style={{
            borderRadius: 25,
            backgroundColor: theme.colors.background,
            padding: 32,
            width: 500,
            minHeight: 380,
          }}>
          <Text type="h2" bold style={{ marginBottom: 12 }}>
            Create Warning
          </Text>
          <View style={{ flex: 1, marginBottom: 24 }}>
            <Text bold center style={{ marginBottom: 12 }}>
              Reason
            </Text>
            <Dropdown
              options={banReasons}
              onChange={({ item }) => setWarningReason(item)}
              shouldSetInitialValue={!!warningReason}
              style={{ width: '100%', alignSelf: 'center' }}
            />
          </View>
          <Input
            grey
            multiline
            rows={3}
            value={warningMessage}
            onChange={(e) => setWarningMessage(e.target.value)}
            placeholder="Message for the user..."
            style={{ resize: 'vertical' }}
          />
          <Input
            grey
            multiline
            rows={3}
            value={warningMessageInternal}
            onChange={(e) => setWarningMessageInternal(e.target.value)}
            placeholder="Internal message..."
            style={{ resize: 'vertical' }}
          />
          <Button
            onPress={() => {
              if (isValidWarning) {
                Modal.hide({
                  id: modalId,
                  extraData: { hasCreatedWarning: true },
                })

                actions.createWarning({
                  userId,
                  textReason: warningMessage,
                  textReasonInternal: warningMessageInternal,
                  reason: WARNING_REASON[warningReason],
                })
              }
            }}
            style={[
              { alignSelf: 'center', marginTop: 24 },
              !isValidWarning && theme.styles.buttonDisabled,
            ]}>
            Submit
          </Button>
        </View>
      </Modal.Body>
    </View>
  )
})

const show = (payload) =>
  Modal.show({
    render: (props) => <CreateWarning {...payload} {...props} />,
    hasBackdrop: true,
    shouldHideOnBackgroundPress: true,
  })

export const ModalCreateWarning = { show }
