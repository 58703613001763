import { blue } from '@ant-design/colors'
import {
  getMessageForBanReason,
  getPreviewMessage,
} from '@tellonym/core/reporting/constants'
import { BAN_REASON } from '@tellonym/enums/lib/User'
import { Alert, Button, Checkbox, DatePicker, Input, Select } from 'antd'
import moment from 'moment'
import React, { useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal, Text, View, helpers, theme } from '../../common'
import { createBan } from '../actions'

const styles = {
  alert: { marginBottom: 24 },
  bgContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  buttonSubmit: { alignSelf: 'center', marginTop: 48 },
  checkboxFirst: { marginTop: 24 },
  checkboxFollowing: { marginLeft: 0, marginTop: 8 },
  mainContainer: {
    backgroundColor: theme.colors.background,
    borderRadius: 25,
    minHeight: 380,
    padding: 32,
    width: 600,
  },
}

const endTimeFooterOptions = [
  { label: '8 hours', value: 8, metric: 'hours' },
  { label: '1 day', value: 1, metric: 'day' },
  { label: '7 days', value: 7, metric: 'days' },
]
const TimeEndPickerFooter = ({ onPressOption }) => (
  <View
    style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 4 }}>
    {endTimeFooterOptions.map(({ label, value, metric }) => (
      <Text
        key={value}
        type="small"
        color={blue.primary}
        onPress={() => onPressOption(moment().add(value, metric))}
        style={{ paddingRight: 16, marginBottom: 2 }}>
        {label}
      </Text>
    ))}
  </View>
)

const banReasonOptions = helpers.mapEnumToSelector(BAN_REASON)

const getIsDateDisabled = (date) => moment().subtract(1, 'minute').isAfter(date)

const CreateBan = ({ modalId, userId, lang = 'en' }) => {
  const dispatch = useDispatch()
  const datePickerRef = useRef(null)
  const [banMessage, setBanMessage] = useState('')
  const [banReason, setBanReason] = useState()
  const [timeEnd, setTimeEnd] = useState()
  const [shouldBanDevices, setShouldBanDevices] = useState(false)
  const [shouldBanUsersUsingSameDevice, setShouldBanUsersUsingSameDevice] = useState(false) // prettier-ignore
  const isValidBan = useMemo(
    () => typeof banReason !== 'undefined',
    [banReason]
  )

  const onTimeBanChange = (moment) => {
    setTimeEnd(moment)
  }

  const onChangeBanReason = (value) => {
    if (value && !banMessage) {
      setBanMessage(getMessageForBanReason(lang, value))
    }

    setBanReason(value)
  }

  const onChangeShouldBanDevices = ({ target: { checked } }) => {
    setShouldBanDevices(checked)
  }

  const onChangeShouldBanUsersUsingSameDevice = ({ target: { checked } }) => {
    setShouldBanUsersUsingSameDevice(checked)
  }

  const onChangeBanMessage = ({ target: { value } }) => {
    setBanMessage(value)
  }

  const onPressOption = (date) => {
    setTimeEnd(date)
    datePickerRef.current.blur()
  }

  const onSubmit = () => {
    if (isValidBan) {
      Modal.hide({ id: modalId, extraData: { hasCreatedBan: true } })

      dispatch(
        createBan({
          userId,
          banCause: banMessage,
          reason: banReason,
          shouldBanDevices,
          shouldBanUsersUsingSameDevice,
          timeEnd,
        })
      )
    }
  }

  return (
    <View style={styles.bgContainer}>
      <Modal.Body style={styles.mainContainer}>
        <Text type="h2" bold style={{ marginBottom: 12 }}>
          Create Ban
        </Text>
        <Alert
          message="Leave end date empty to create a permanent ban"
          type="info"
          showIcon
          style={styles.alert}
        />
        <View style={{ flexDirection: 'row', marginBottom: 12 }}>
          <View style={{ flex: 1, marginRight: 6 }}>
            <Text bold center style={{ marginBottom: 12 }}>
              Reason
            </Text>
            <Select
              options={banReasonOptions}
              onChange={onChangeBanReason}
              style={{ width: '100%', alignSelf: 'center' }}
            />
          </View>
          <View style={{ flex: 1, marginLeft: 6 }}>
            <Text bold center style={{ marginBottom: 12 }}>
              Timeban till
            </Text>
            <DatePicker
              ref={datePickerRef}
              allowClear
              disabledDate={getIsDateDisabled}
              disabledTime={getIsDateDisabled}
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              onChange={onTimeBanChange}
              value={timeEnd}
            />
            <TimeEndPickerFooter onPressOption={onPressOption} />
          </View>
        </View>
        <Input.TextArea
          allowClear
          autoSize={{ minRows: 4, maxRows: 8 }}
          placeholder="Message for the user..."
          onChange={onChangeBanMessage}
          value={banMessage}
        />
        <Checkbox
          onChange={onChangeShouldBanDevices}
          style={styles.checkboxFirst}>
          Should Ban All Devices
        </Checkbox>
        <Checkbox
          onChange={onChangeShouldBanUsersUsingSameDevice}
          style={styles.checkboxFollowing}>
          Should Ban All Users Using Same Device
        </Checkbox>
        <Text bold style={{ marginTop: 24 }}>
          The user will see:
        </Text>
        <Text style={{ fontStyle: 'italic', flex: 1, wordBreak: 'break-word' }}>
          {getPreviewMessage(lang, banMessage, timeEnd)}
        </Text>
        <Button
          type="primary"
          shape="round"
          disabled={!isValidBan}
          onClick={onSubmit}
          style={styles.buttonSubmit}>
          Submit
        </Button>
      </Modal.Body>
    </View>
  )
}

const show = (payload) =>
  Modal.show({
    render: (props) => <CreateBan {...payload} {...props} />,
    hasBackdrop: true,
    shouldHideOnBackgroundPress: true,
  })

export const ModalCreateBan = { show }
