import { LOGOUT, LOGOUT_SUCCESS } from '@tellonym/core/profile/types'
import { changeState as changeUserState } from '@tellonym/core/user/actions'
import { API_CODE } from '@tellonym/enums/lib/ApiCode'
import { Alert } from '../common'

export const errorHandler = async ({
  code = 'NO_CODE',
  dispatch,
  isLoggedIn,
  message = '',
  type,
}) => {
  switch (code) {
    case API_CODE.TOKEN_INVALID: {
      if (isLoggedIn) {
        dispatch({ type: LOGOUT })
        return dispatch({ type: LOGOUT_SUCCESS })
      }
      return undefined
    }

    case API_CODE.CAPTCHA_INVALID:
      dispatch(changeUserState({ captcha: undefined }))
      return Alert.error(`${code} ${message}`)

    case API_CODE.NOT_FOUND: {
      if (window.location.pathname.startsWith('/artificialtells')) {
        return undefined
      }
      return Alert.error(message)
    }

    default: {
      if (process.env.NODE_ENV === 'development') {
        console.error(`[${type}]: ${message || code}`) // eslint-disable-line no-console
      }

      return Alert.error(`${code} ${message}`)
    }
  }
}
