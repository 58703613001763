import { colors } from '@tellonym/core/common/colorSystem'
import { COMMENT_STATUS } from '@tellonym/enums/lib/Comments'
import { POST_STATUS, POST_TYPE } from '@tellonym/enums/lib/post'
import {
  DETECTION_TYPE,
  SENDER_STATUS,
  TELL_STATUS,
  TELL_TYPE_NEW,
} from '@tellonym/enums/lib/Tell'
import { Button } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Box, Text, TextTranslatable } from '../../common'
import { RNImage } from '../../common/components/Image'
import { fixTypeCasing } from '../../common/helpers'
import {
  blockAnswer,
  blockComment,
  blockPost,
  blockTell,
  copyItems,
  restoreAnswer,
  restoreComment,
  restoreTell,
  setSelection,
} from '../actions'
import { AdditionalInfos } from './AdditionalInfos'
import { AnswerPicture } from './AnswerPicture'
import { ModalPostComments } from './ModalPostComments'

const TAB_TYPE = {
  ANSWERS: 'answers',
  COMMENTS: 'comments',
  SENT_TELLS: 'sentTells',
  TELLS: 'tells',
}

const STATUS_TYPE = {
  ACCEPTED: 'ACCEPTED',
  ACTIVE: 'ACTIVE',
  ANSWERED: 'ANSWERED',
  BLOCKED: 'BLOCKED',
  DELETED: 'DELETED',
  UNSENT: 'UNSENT',
  WAITING_FOR_REVIEW: 'WAITING_FOR_REVIEW',
}

const senderStatusMap = {
  [SENDER_STATUS.ANONYMOUS]: '[anon]',
  [SENDER_STATUS.HIDDEN]: '[anon] [public]',
  [SENDER_STATUS.HIDDEN_BY_SENDER]: '[hidden]',
  [SENDER_STATUS.PUBLIC]: '[public]',
}

const getUsernameWithSenderStatus = (item, username) => {
  if (username) {
    if (username.length > 12) {
      return `${username.slice(0, 12)}... ${senderStatusMap[item.senderStatus]}`
    }

    return `${username} ${senderStatusMap[item.senderStatus]}`
  }

  return item.senderIp
}

const parseItem = (item, userId, type) => {
  switch (type) {
    case TAB_TYPE.ANSWERS:
      return {
        id: item.id,
        createdAt: item.postCreatedAt,
        status: POST_STATUS[item.postStatus],
        type: item.postType,
        contentItems: [
          item.parent?.content,
          item.tellContent,
          item.postContent,
        ],
        media: item.media,
        footerItems: [
          {
            width: 280,
            label: 'Sender:',
            value:
              item.postType === POST_TYPE.REQUEST_TELL
                ? item.receiverUsername
                : getUsernameWithSenderStatus(item, item.senderUsername),
            href: `${document.location.origin}/user/${
              item.postType === POST_TYPE.REQUEST_TELL ? userId : item.senderId
            }`,
          },
          {
            label: 'Likes:',
            value: item.likesCount ?? 0,
          },
          {
            label: 'Comments:',
            value: item.commentsCount ?? 0,
            onPress:
              item.commentsCount > 0
                ? () => {
                    ModalPostComments.show({ item })
                  }
                : undefined,
          },
        ],
        _isSelected: item._isSelected,
      }

    case TAB_TYPE.COMMENTS:
      return {
        id: item.id,
        createdAt: item.createdAt,
        status: COMMENT_STATUS[item.status],
        type: 'comment',
        content: item.content,
        footerItems: [
          {
            width: 280,
            label: 'To:',
            value: item.post.user.username,
            href: `${document.location.origin}/user/${item.post.user.id}`,
          },
          {
            label: 'Post ID:',
            value: String(item.post.id).slice(-3),
            valueColor: colors.blue[7],
            href: `https://tellonym.me/${item.post.user.username}/answer/${item.post.id}`,
          },
          {
            label: 'Comments:',
            value: item.post.comments?.amount ?? 0,
            onPress:
              item.post.comments?.amount > 0
                ? () => {
                    ModalPostComments.show({ item: item.post })
                  }
                : undefined,
          },
        ],
        _isSelected: item._isSelected,
      }

    case TAB_TYPE.SENT_TELLS:
      return {
        id: item.id,
        createdAt: item.tellCreatedAt,
        status: TELL_STATUS[item.tellStatus],
        blockReason: item.tellDetectionType
          ? fixTypeCasing(DETECTION_TYPE[item.tellDetectionType])
          : undefined,
        type: item.tellType,
        contentItems: [
          item.parent?.content,
          item.tellContent,
          item.postContent,
        ],
        media: item.media,
        footerItems: [
          {
            width: 280,
            label: 'Receiver:',
            value: getUsernameWithSenderStatus(item, item.receiverUsername),
            href: `${document.location.origin}/user/${item.receiverId}`,
          },
          {
            label: 'Type:',
            value: fixTypeCasing(TELL_TYPE_NEW[item.tellType]),
          },
          {
            label: 'Likes:',
            value: item.likesCount ?? 0,
          },
          {
            label: 'Comments:',
            value: item.commentsCount ?? 0,
          },
        ],
        _isSelected: item._isSelected,
      }

    case TAB_TYPE.TELLS:
      return {
        id: item.id,
        createdAt: item.tellCreatedAt,
        status: TELL_STATUS[item.tellStatus],
        blockReason: item.tellDetectionType
          ? fixTypeCasing(DETECTION_TYPE[item.tellDetectionType])
          : undefined,
        type: item.tellType,
        contentItems: [item.parent?.content, item.tellContent],
        footerItems: [
          {
            width: 280,
            label: 'Sender:',
            value: getUsernameWithSenderStatus(item, item.senderUsername),
            href: `${document.location.origin}/user/${item.senderId}`,
          },
          {
            label: 'Type:',
            value: fixTypeCasing(TELL_TYPE_NEW[item.tellType]),
          },
        ],
        _isSelected: item._isSelected,
      }

    default:
      return item
  }
}

const parseUserId = (item, userIdFromProps) => {
  switch (item.type) {
    case TAB_TYPE.ANSWERS:
      return item.receiverId

    case TAB_TYPE.COMMENTS:
      return userIdFromProps

    case TAB_TYPE.SENT_TELLS:
      return item.senderId

    case TAB_TYPE.TELLS:
      return item.receiverId

    default:
      return userIdFromProps
  }
}

const Tag = ({ item, ...props }) => {
  return (
    <Box
      alignItems="center"
      justifyContent="center"
      opacity={
        [STATUS_TYPE.ACTIVE, STATUS_TYPE.ANSWERED].includes(item.status) ? 0 : 1
      }
      width={130}
      paddingHorizontal={7}
      paddingVertical={2}
      borderRadius={4}
      backgroundColor={
        {
          [STATUS_TYPE.ACCEPTED]: colors.green[3],
          [STATUS_TYPE.ACTIVE]: colors.green[3],
          [STATUS_TYPE.BLOCKED]: colors.red[4],
          [STATUS_TYPE.DELETED]: colors.grey[1],
        }[item.status]
      }
      {...props}>
      <Text
        type="note"
        center
        color={
          {
            [STATUS_TYPE.ACCEPTED]: colors.white[1],
            [STATUS_TYPE.ACTIVE]: colors.white[1],
            [STATUS_TYPE.BLOCKED]: colors.red[1],
            [STATUS_TYPE.DELETED]: colors.grey[6],
          }[item.status]
        }
        style={{
          fontWeight: '500',
          wordBreak: 'break-word',
        }}>
        {item.status === STATUS_TYPE.BLOCKED && item.blockReason
          ? item.blockReason.replace('Spam ', '')
          : fixTypeCasing(item.status)}
      </Text>
    </Box>
  )
}

const Content = ({ isTellTranslatable, item, shouldTranslate, type }) => {
  const TextComponent = isTellTranslatable ? TextTranslatable : Text

  switch (type) {
    case TAB_TYPE.ANSWERS:
    case TAB_TYPE.SENT_TELLS:
    case TAB_TYPE.TELLS:
      return (
        <Box gap={8} transparent>
          {Boolean(item.contentItems[0]) && (
            <Box alignItems="flex-start" transparent>
              <Box
                backgroundColor={colors.saphire[2]}
                borderRadius={8}
                paddingHorizontal={8}
                paddingVertical={4}
                transparent>
                <Text>{item.contentItems[0]}</Text>
              </Box>
            </Box>
          )}

          {Boolean(item.contentItems[1]) && (
            <Box
              borderLeftWidth={3}
              borderLeftColor={colors.saphire[6]}
              borderLeftStyle="solid"
              paddingLeft={6}
              transparent>
              <TextComponent
                shouldAlignButtonBottom
                shouldAlignButtonRight
                shouldTranslate={shouldTranslate}
                backgroundColor={colors.grey[11]}>
                {item.contentItems[1]}
              </TextComponent>
            </Box>
          )}

          {Boolean(item.contentItems[2]) && (
            <TextComponent
              shouldAlignButtonRight
              shouldTranslate={shouldTranslate}
              backgroundColor={colors.grey[11]}>
              {item.contentItems[2]}
            </TextComponent>
          )}

          <AnswerPicture media={item.media} />
        </Box>
      )

    case TAB_TYPE.COMMENTS:
      return (
        <TextComponent
          shouldAlignButtonRight
          shouldTranslate={shouldTranslate}
          backgroundColor={colors.grey[11]}>
          {item.content ?? '-'}
        </TextComponent>
      )

    default:
      return null
  }
}

const FooterItem = ({
  href,
  label,
  labelColor,
  value,
  valueColor = colors.black[2],
  onPress,
  ...props
}) => {
  return (
    <Box flexDirection="row" gap={4} {...props}>
      <Text type="note" color={labelColor} style={{ whiteSpace: 'nowrap' }}>
        {label}
      </Text>
      {typeof href === 'string' ? (
        <a
          href={href}
          style={{
            color: valueColor,
            fontSize: 12,
            fontStyle: 'italic',
            whiteSpace: 'nowrap',
          }}>
          {value}
        </a>
      ) : (
        <Text
          type="note"
          color={valueColor}
          onPress={onPress}
          style={{ fontStyle: 'italic', whiteSpace: 'nowrap' }}>
          {value}
        </Text>
      )}
    </Box>
  )
}

export const ContentItem = ({
  isTellTranslatable = true,
  item: itemFromProps,
  shouldTranslate,
  spamItemId,
  type,
  userId: userIdFromProps,
}) => {
  const dispatch = useDispatch()

  const item = parseItem(itemFromProps, userIdFromProps, type)
  const userId = parseUserId(itemFromProps, userIdFromProps)

  const hasRestoreOption = [
    STATUS_TYPE.BLOCKED,
    STATUS_TYPE.DELETED,
    STATUS_TYPE.WAITING_FOR_REVIEW,
  ].includes(item.status)

  const hasInfoModal = [
    TAB_TYPE.ANSWERS,
    TAB_TYPE.SENT_TELLS,
    TAB_TYPE.TELLS,
  ].includes(type)

  const onPressBlockOrRestore = () => {
    let action

    switch (type) {
      case TAB_TYPE.ANSWERS:
        if (item.type === POST_TYPE.REQUEST_TELL) {
          action = hasRestoreOption ? restoreAnswer : blockPost
        } else {
          action = hasRestoreOption ? restoreAnswer : blockAnswer
        }
        break

      case TAB_TYPE.COMMENTS:
        action = hasRestoreOption ? restoreComment : blockComment
        break

      case TAB_TYPE.SENT_TELLS:
      case TAB_TYPE.TELLS:
        action = hasRestoreOption ? restoreTell : blockTell
        break

      default:
        break
    }

    dispatch(
      action({
        id: item.id,
        userId,
        _type: type,
        _userId: userId,
        _spamItemId: spamItemId,
      })
    )
  }

  const onPressCopy = () => {
    dispatch(copyItems({ items: [itemFromProps], type }))
  }

  const onPressOpenInfoModal = () => {
    AdditionalInfos.show({ item: itemFromProps })
  }

  const onChangeSelection = (e) => {
    dispatch(
      setSelection({
        type,
        tellId: item.id,
        isSelected: e.target.checked,
        userId,
      })
    )
  }

  return (
    <Box paddingVertical={12}>
      <Box
        paddingHorizontal={12}
        paddingVertical={20}
        gap={16}
        borderRadius={16}
        borderWidth={1}
        borderStyle="solid"
        borderColor={colors.grey[1]}>
        <Box flexDirection="row" gap={16}>
          <Box>
            <Tag item={item} />
          </Box>

          <Box flex={1}>
            <Box
              paddingHorizontal={8}
              paddingVertical={12}
              gap={12}
              borderRadius={8}
              backgroundColor={colors.saphire[1]}>
              <Content
                isTellTranslatable={isTellTranslatable}
                item={item}
                shouldTranslate={shouldTranslate}
                type={type}
              />
            </Box>

            <Box
              flexDirection="row"
              justifyContent="space-between"
              paddingTop={8}>
              <Box flexDirection="row" gap={24}>
                {item.footerItems?.map((footerItem) => (
                  <FooterItem key={footerItem.label} {...footerItem} />
                ))}
              </Box>

              <Box
                flexDirection="row"
                gap={16}
                alignItems="center"
                paddingLeft={16}>
                <Text
                  type="note"
                  color={colors.black[2]}
                  style={{ fontStyle: 'italic', whiteSpace: 'nowrap' }}>
                  {dayjs(item.createdAt).format('DD.MM.YYYY HH:mm')}
                </Text>

                {hasInfoModal && (
                  <Box
                    justifyContent="center"
                    alignItems="center"
                    onPress={onPressOpenInfoModal}
                    borderRadius={28}>
                    <RNImage
                      source={require('../../common/assets/info-circle.png')}
                      style={{
                        width: 14,
                        height: 14,
                        borderRadius: 28,
                        tintColor: colors.black[1],
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          <Box flexDirection="row" gap={16} alignItems="flex-start">
            <Box gap={4}>
              <Button onClick={onPressBlockOrRestore} style={{ width: 86 }}>
                {hasRestoreOption ? 'Restore' : 'Block'}
              </Button>

              <Button onClick={onPressCopy} style={{ width: 86 }}>
                Copy
              </Button>
            </Box>

            <input
              type="checkbox"
              name="isSelected"
              checked={
                typeof item._isSelected === 'undefined'
                  ? false
                  : item._isSelected
              }
              onChange={onChangeSelection}
              style={{
                width: 16,
                height: 16,
                borderRadius: 2,
                borderWidth: 1,
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
