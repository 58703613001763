import React from 'react'
import * as ReactRedux from 'react-redux'
import { Box, FlatList, history, Modal, Text, theme } from '../../common'
import { fetchCommentsForPost, refreshCommentsForPost } from '../actions'
import { CommentsForPost } from '../selectors'

const Item = ({ closeModal, item }) => {
  const onPressUsername = () => {
    closeModal()
    history.push(`/user/${item.user?.id}`)
  }

  return (
    <Box
      flexDirection="row"
      gap={8}
      paddingVertical={8}
      alignItems="flex-start">
      <Text
        type="small"
        bold
        onPress={onPressUsername}
        style={{
          whiteSpace: 'nowrap',
        }}>{`@${item.user?.username}`}</Text>
      <Box>{item.content}</Box>
    </Box>
  )
}

const ModalPostCommentsComponent = ({ item, modalId }) => {
  const dispatch = ReactRedux.useDispatch()
  const state = ReactRedux.useSelector(CommentsForPost)

  React.useEffect(() => {
    dispatch(refreshCommentsForPost({ postId: item.id }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeModal = () => {
    Modal.hide({ id: modalId })
  }

  return (
    <Box
      flex={1}
      alignItems="center"
      justifyContent="center"
      paddingHorizontal={64}
      transparent>
      <Modal.Body
        style={{
          backgroundColor: theme.colors.background,
          borderRadius: 25,
          padding: 32,
          paddingHorizontal: 32,
          borderWidth: 1,
          borderStyle: 'solid',
          height: window.innerHeight - 100,
        }}>
        <FlatList
          actions={{
            fetch: (payload) => {
              dispatch(fetchCommentsForPost({ postId: item.id, ...payload }))
            },
            refresh: (payload) => {
              dispatch(refreshCommentsForPost({ postId: item.id, ...payload }))
            },
          }}
          component={Item}
          extraData={{
            hasMore: state.hasMore,
            isFetching: state.isFetching,
            isRefreshing: state.isRefreshing,
          }}
          extraProps={{
            closeModal,
          }}
          hasMore={state.hasMore}
          isFetching={state.isFetching}
          isRefreshing={state.isRefreshing}
          items={state.data[item.id] || { ids: [], data: {} }}
          contentContainerStyle={{
            height: window.innerHeight - 180,
            flex: undefined,
          }}
          style={{
            minWidth: 400,
            maxWidth: window.innerWidth - 600,
          }}
        />
      </Modal.Body>
    </Box>
  )
}

const show = (payload) =>
  Modal.show({
    render: (props) => <ModalPostCommentsComponent {...payload} {...props} />,
    hasBackdrop: true,
    shouldHideOnBackgroundPress: true,
  })

export const ModalPostComments = {
  show,
}
