import { ARTIFICIAL_TELL_USER_GROUP_TYPE } from '@tellonym/enums/lib/Tell'
import { Segmented, Spin, Typography } from 'antd'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { Box, hooks, theme } from '../../common'
import { useAllGroupsQuery } from '../queries'
import { getLanguage } from '../selectorsV2'
import { ArtificialTellTopicGroupsTable } from './ArtificialTellTopicGroupsTable'
import { SelectorsLanguage } from './SelectorsLanguage'

/**
 * This is manually measured (with chrome debug element inspection) and necessary to prevent the page from
 * being scrollable. We don't want that because the table is already scrollable.
 */
const HEADER_HEIGHT = 96

const Header = ({ dataMode, onChangeDataMode }) => {
  return (
    <Box
      padding={24}
      backgroundColor={theme.colors.antdBackgroundElevated}
      width="100%"
      flexDirection="row">
      <Box
        flex={1}
        justifyContent="center"
        alignItems="flex-end"
        backgroundColor={theme.colors.antdBackgroundElevated}
        paddingHorizontal={48}>
        <Box alignItems="center">
          <Typography.Title level={5} style={{ marginBottom: 0 }}>
            Data mode:
          </Typography.Title>

          <Segmented
            value={dataMode}
            options={[
              {
                label: ARTIFICIAL_TELL_USER_GROUP_TYPE[2],
                value: ARTIFICIAL_TELL_USER_GROUP_TYPE.HIGH,
              },
              {
                label: ARTIFICIAL_TELL_USER_GROUP_TYPE[0],
                value: ARTIFICIAL_TELL_USER_GROUP_TYPE.LOW,
              },
              {
                label: ARTIFICIAL_TELL_USER_GROUP_TYPE[3],
                value: ARTIFICIAL_TELL_USER_GROUP_TYPE.REAL,
              },
            ]}
            onChange={onChangeDataMode}
            size="small"
          />
        </Box>
      </Box>
      <Box flex={1} alignItems="flex-end" transparent>
        <SelectorsLanguage />
      </Box>
    </Box>
  )
}

export const PageArtificialTellsAllGroups = () => {
  const language = ReactRedux.useSelector(getLanguage)

  const table = hooks.useTableState()

  const [dataMode, setDataMode] = React.useState(
    ARTIFICIAL_TELL_USER_GROUP_TYPE.HIGH
  )

  const { data, isLoading } = useAllGroupsQuery({
    language,
    dataMode,
  })

  return (
    <Spin spinning={isLoading}>
      <Box>
        <Header
          dataMode={dataMode}
          onChangeDataMode={setDataMode}
          language={language}
        />
        <ArtificialTellTopicGroupsTable
          {...table}
          dataSource={data?.groups}
          language={language}
          offset={HEADER_HEIGHT}
        />
      </Box>
    </Spin>
  )
}
