import { countriesToTrack } from '@tellonym/enums/lib/Stats'
import { Button, Checkbox, Collapse, Tooltip, Typography } from 'antd'
import { compose, mergeAll } from 'ramda'
import React from 'react'
import Helmet from 'react-helmet'
import * as Redux from 'react-redux'
import { Box, Text, View, colors, styleSheets } from '../../common'
import { convertArrayToOptions } from '../../common/helpers'
import * as hooks from '../../common/hooks'
import { useDebugQuery } from '../../common/queries'
import { refreshChShortnames } from '../actions'
import { dateTypes, statsTypes } from '../constants'

import { useSelectedShortnames } from '../hooks'
import { useCompareMutation } from '../queries'
import { getChShortnamesState } from '../selectors'
import { InputValidation } from './InputValidation'
import { ShortNamesSelector } from './ShortNamesSelector'
import { TimeFramePicker } from './TimeFramePicker'

const styles = {
  checkboxText: { marginLeft: 8 },
  compareSelector: { width: 130, marginLeft: 12 },
  headerContainer: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    marginTop: 24,
    marginBottom: 12,
  },
  italic: { fontStyle: 'italic' },
  smallTitle: { marginTop: 24 },
}

const ValuesSelector = ({
  defaultDateType,
  experimentLabel,
  isLoading,
  hasCountry,
  onChangeCohort,
  onChangeCountry,
  onChangeDates,
  onChangeExperimentId,
  onChangeExpPercentage,
  onChangeDateType,
  onChangeShouldSummarize,
  onChangeStatsType,
  title,
}) => {
  return (
    <Box flex={1} paddingRight={12}>
      <Typography.Title level={3}>{title}</Typography.Title>
      <Box marginLeft={12}>
        <TimeFramePicker
          defaults={{
            dateType: defaultDateType ?? dateTypes.LAST_21_DAYS,
            startDate: undefined,
            endDate: undefined,
          }}
          isIntervalTypeVisible={false}
          onChangeDateType={onChangeDateType}
          onChangeDates={onChangeDates}
        />

        <InputValidation
          isOptional
          isDisabled={isLoading}
          onChangeText={onChangeExperimentId}
          text={experimentLabel ?? 'Experiment Id'}
          validateValue={(value) => Number.isInteger(Number(value))}
        />

        {typeof onChangeExpPercentage === 'function' && (
          <InputValidation
            isOptional
            isDisabled={isLoading}
            onChangeText={onChangeExpPercentage}
            text="Exp Percentage"
            placeholder="0.5"
            validateValue={(value) => !Number.isNaN(Number(value))}
          />
        )}

        <InputValidation
          isOptional
          isDisabled={isLoading}
          onChangeText={onChangeCohort}
          text="Cohort"
        />

        {hasCountry && (
          <InputValidation
            defaultValue="DE"
            options={countriesToTrack.map((v) => ({ label: v, value: v }))}
            isDisabled={isLoading}
            onChangeText={onChangeCountry}
            text="Country"
          />
        )}

        {typeof onChangeStatsType === 'function' && (
          <InputValidation
            isOptional
            options={convertArrayToOptions(Object.keys(statsTypes))}
            isDisabled={isLoading}
            onChangeText={onChangeStatsType}
            text="Stats Type"
            onPressText={() =>
              window.open(
                'https://www.notion.so/tellonym/ModCP-Analytics-de38be8628df4cadb01c389043b1407e',
                '_blank'
              )
            }
          />
        )}

        {typeof onChangeShouldSummarize === 'function' && (
          <Tooltip title="By default the average is used, check to sum them up instead.">
            <Checkbox
              defaultValue="Default"
              onChange={onChangeShouldSummarize}
              style={{ marginTop: 12 }}>
              Should summarize values
            </Checkbox>
          </Tooltip>
        )}
      </Box>
    </Box>
  )
}

const stringifyObject = (obj) =>
  Object.keys(obj)
    .reduce((acc, key) => {
      acc.push(`${key}: ${JSON.stringify(obj[key])}`)

      return acc
    }, [])
    .join('\n')

const createExpDataToRender = (remoteExperimentConfig) => {
  if (!remoteExperimentConfig) {
    return []
  }

  const { experimentsData, experimentsCountryMapping } = remoteExperimentConfig

  const countryKeys = Object.keys(experimentsCountryMapping)

  const { base, countriesBase, ...expData } = experimentsData

  const mappedExperiments = Object.keys(expData).reduce((acc, key) => {
    if (['base', 'countriesBase'].includes(key)) {
      return acc
    }

    const countries = countryKeys.reduce((acc, curr) => {
      if (experimentsCountryMapping[curr][key] >= 0) {
        acc.push(curr)

        return acc
      }

      return acc
    }, [])

    const countriesString = countries
      .map(
        (c) =>
          `${c.toLocaleUpperCase()} (${
            experimentsCountryMapping[c][key] * 100
          }%)`
      )
      .join(', ')
      .replace('all', 'global')
      .toLocaleUpperCase()

    const exp = experimentsData[key]

    const header = `${key} - ${exp.name} - ${
      countriesString !== '' ? countriesString : 'nowhere'
    }`

    const triggers = exp.triggers?.join?.(', ')

    const configString = stringifyObject(exp.config)

    const baseString = stringifyObject(base)

    const countryBaseForExp = compose(stringifyObject, mergeAll, (c) =>
      c.map((curr) => countriesBase?.[curr], [])
    )(countries)

    acc.push({
      id: key,
      header,
      triggers,
      shouldApplyOnRestart: exp.shouldApplyOnRestart,
      config: configString,
      base: baseString,
      countriesBase: countryBaseForExp,
    })

    return acc
  }, [])

  return mappedExperiments
}

const ExpConfigViewer = () => {
  const [shouldShowRaw, setShouldShowRaw] = React.useState(false)
  const { data } = useDebugQuery()

  const exps = React.useMemo(
    () => createExpDataToRender(data?.remoteExperimentConfig),
    [data?.remoteExperimentConfig]
  )

  if (typeof data?.remoteExperimentConfig === 'undefined') {
    return null
  }

  const onChangeCheckbox = (e) => {
    setShouldShowRaw(e.target.checked)
  }

  return (
    <>
      <Box flexDirection="row" marginBottom={12} alignItems="center">
        <Checkbox onChange={onChangeCheckbox} />
        <Text style={styles.checkboxText}>show raw</Text>
      </Box>
      {shouldShowRaw ? (
        <pre>{JSON.stringify(data.remoteExperimentConfig, null, 2)}</pre>
      ) : (
        <Box>
          <Collapse ghost>
            {exps.map((exp) => (
              <Collapse.Panel header={exp.header} key={exp.id}>
                <Box marginLeft={64}>
                  <Text>{`Trigger: ${exp.triggers}`}</Text>
                  {exp.shouldApplyOnRestart && <Text>Applies on restart</Text>}
                  <Typography.Title level={4} style={styles.smallTitle}>
                    Config
                  </Typography.Title>
                  <Text>{exp.config}</Text>
                  {exp.countriesBase && (
                    <>
                      <Typography.Title level={4} style={styles.smallTitle}>
                        Countries Config
                      </Typography.Title>
                      <Text>{exp.countriesBase}</Text>
                    </>
                  )}
                  {exp.base && (
                    <>
                      <Typography.Title level={4} style={styles.smallTitle}>
                        Base Config
                      </Typography.Title>
                      <Text>{exp.base}</Text>
                    </>
                  )}
                </Box>
              </Collapse.Panel>
            ))}
          </Collapse>
        </Box>
      )}
    </>
  )
}

const useValueSelectorFactory = (payload) => {
  const [values, setValues] = React.useState({
    dateType: payload?.dateType ?? dateTypes.LAST_21_DAYS,
    country: 'DE',
  })

  const onChangeCreator = (key) => (value) => {
    setValues({ ...values, [key]: value })
  }

  const onChangeCountry = ([country]) => {
    setValues({ ...values, country })
  }

  const onChangeDates = (dates, dateType) => {
    setValues({ ...values, dates, dateType })
  }

  const setterCollection = {
    onChangeCohort: onChangeCreator('cohort'),
    onChangeCountry,
    onChangeDates,
    onChangeExperimentId: onChangeCreator('experimentId'),
    onChangeDateType: onChangeCreator('dateType'),
  }

  return { ...values, setter: setterCollection }
}

const PageChCompare = () => {
  const dispatch = Redux.useDispatch()

  const containerStyle = hooks.usePageContainerStyle()
  const control = useValueSelectorFactory()
  const experiment = useValueSelectorFactory({ dateType: dateTypes.SYNCED })
  const [shouldSummarize, setShouldSummarize] = React.useState(false)
  const [expPercentage, setExpPercentage] = React.useState(0.5)
  const [statsType, setStatsType] = React.useState()

  const { data, isLoading, isSuccess, mutate } = useCompareMutation()

  const { data: shortnamesData, isRefreshing: isRefreshingShortnames } =
    Redux.useSelector(getChShortnamesState)

  React.useEffect(() => {
    dispatch(refreshChShortnames())
  }, [])

  const selectedShortnames = useSelectedShortnames()

  const onPressRefresh = () => {
    mutate({
      control,
      experiment,
      expPercentage,
      shortNames: selectedShortnames,
      shouldSummarize,
      statsType,
    })
  }

  const onPressOpenSheet = () => {
    if (isSuccess && typeof data?.sheetUrl === 'string') {
      window.open(data.sheetUrl, '_blank')
    }
  }

  const onChangeShouldSummarize = (e) => {
    setShouldSummarize(e.target.checked)
  }

  const onChangeExpPercentage = (value) => {
    setExpPercentage(value)
  }

  const onChangeStatsType = ([value]) => {
    setStatsType(value)
  }

  return (
    <View style={containerStyle}>
      <Helmet title="Compare - Tellonym Modcp" />
      <Box padding={16} backgroundColor={colors.background}>
        <Typography.Title>Statistic Compare</Typography.Title>
        <Box marginTop={24} flexDirection="row">
          <ValuesSelector
            title="Control Group"
            experimentLabel="Control EXP id"
            isLoading={isLoading}
            hasCountry
            shouldSummarize={shouldSummarize}
            onChangeShouldSummarize={onChangeShouldSummarize}
            onChangeStatsType={onChangeStatsType}
            {...control.setter}
          />
          <ValuesSelector
            defaultDateType={dateTypes.SYNCED}
            title="Experiment Group"
            isLoading={isLoading}
            onChangeExpPercentage={onChangeExpPercentage}
            {...experiment.setter}
          />
        </Box>
        <Box flex={1} marginTop={48} alignItems="center">
          <Typography.Title level={3}>Shortnames</Typography.Title>
          <Box marginTop={24} width={800}>
            <ShortNamesSelector
              groups={shortnamesData}
              isRefreshing={isRefreshingShortnames}
            />
          </Box>
          <Box marginTop={48} flexDirection="row">
            <Button
              type="primary"
              size="large"
              shape="round"
              loading={isLoading}
              onClick={onPressRefresh}>
              Generate Sheet
            </Button>
            <Button
              disabled={!isSuccess}
              size="large"
              shape="round"
              onClick={onPressOpenSheet}
              style={styleSheets.margin.left[12]}>
              Open Sheet
            </Button>
          </Box>
        </Box>
        <Box marginTop={48}>
          <Typography.Title level={3}>Experiment Config</Typography.Title>
          <ExpConfigViewer />
        </Box>
      </Box>
    </View>
  )
}

export { PageChCompare }
