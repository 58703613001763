import {
  STATUS_EMOJI,
  STATUS_EMOJI_TYPE,
  TINT_COLOR,
} from '@tellonym/enums/lib/User'
import * as _ from '../underscore'

export const name = 'profile'

export const supportedEmojiTypes = [
  STATUS_EMOJI_TYPE.ACHIEVABLE,
  STATUS_EMOJI_TYPE.FREE,
  STATUS_EMOJI_TYPE.PURCHASABLE,
  STATUS_EMOJI_TYPE.PREMIUM,
]

export const supportedEmojis = [
  STATUS_EMOJI.SMILE_HEARTS,
  STATUS_EMOJI.SUNGLASSES,
  STATUS_EMOJI.WINK,
  STATUS_EMOJI.ANGRY_CENSORED,
  STATUS_EMOJI.CRYING,
  STATUS_EMOJI.SAD,
  STATUS_EMOJI.SICK,
  STATUS_EMOJI.STRONG,
  STATUS_EMOJI.EXPLODING_HEAD,
  STATUS_EMOJI.SMILING_EVIL,
  STATUS_EMOJI.CELEBRATING,
  STATUS_EMOJI.POO_FACE,
  STATUS_EMOJI.STAR_EYES,
  STATUS_EMOJI.HEART_EYES,
  STATUS_EMOJI.VIRUS,
  STATUS_EMOJI.TOILET_PAPER,
  STATUS_EMOJI.FACE_TEAR,
  STATUS_EMOJI.PARTY,
  STATUS_EMOJI.FLAME,
  STATUS_EMOJI.STAR,
  STATUS_EMOJI.GHOST,
  STATUS_EMOJI.FIST_BUMP,
  STATUS_EMOJI.UNICORN,
  STATUS_EMOJI.APE_HEAR_NO_EVIL,
  STATUS_EMOJI.RAINBOW,
  STATUS_EMOJI.SKULL_AND_CROSSBONES,
  STATUS_EMOJI.DIAMOND,
  STATUS_EMOJI.SNOWFLAKE,
  STATUS_EMOJI.PIZZA,
  STATUS_EMOJI.CHAMPAGNE,
  STATUS_EMOJI.SOCCER,
  STATUS_EMOJI.BASEKTBALL,
  STATUS_EMOJI.HEADPHONES,
  STATUS_EMOJI.VIDEO_GAME,
  STATUS_EMOJI.RACING_CAR,
  STATUS_EMOJI.UNDERAGE,
  STATUS_EMOJI.BEER,
  STATUS_EMOJI.SPAGHETTI,
  STATUS_EMOJI.SYRINGE,
  STATUS_EMOJI.GORILLA,
  STATUS_EMOJI.CROWN,
  STATUS_EMOJI.NAIL_CARE,
  STATUS_EMOJI.ROCKET,
  STATUS_EMOJI.DOG,
  STATUS_EMOJI.WARNING,
  STATUS_EMOJI.BUTTERFLY,
  STATUS_EMOJI.US,
  STATUS_EMOJI.TADA,
  STATUS_EMOJI.HAMBURGER,
  STATUS_EMOJI.DYNAMITE,
  STATUS_EMOJI.BASEBALL,
  STATUS_EMOJI.GUITAR,
  STATUS_EMOJI.EAGLE,
  STATUS_EMOJI.STATUE_OF_LIBERTY,
  STATUS_EMOJI.BEE,
  STATUS_EMOJI.BEETLE,
  STATUS_EMOJI.ICECREAM,
  STATUS_EMOJI.SHARK,
  STATUS_EMOJI.DOLPHIN,
  STATUS_EMOJI.SWEAT_DROPS,
  STATUS_EMOJI.GUN,
  STATUS_EMOJI.MOTOR_BOAT,
  STATUS_EMOJI.CHERRY_BLOSSOM,
  STATUS_EMOJI.SUNNY,
  STATUS_EMOJI.ROSE,
  STATUS_EMOJI.SHAMROCK,
  STATUS_EMOJI.WILTED_FLOWER,
  STATUS_EMOJI.TORNADO,
  STATUS_EMOJI.COMET,
  STATUS_EMOJI.HOTDOG,
  STATUS_EMOJI.FRIES,
  STATUS_EMOJI.DOUGHNUT,
  STATUS_EMOJI.BEERS,
  STATUS_EMOJI.DNA,
  STATUS_EMOJI.COFFIN,
  STATUS_EMOJI.SMOKING,
  STATUS_EMOJI.PILL,
  STATUS_EMOJI.HUNDRED,
  STATUS_EMOJI.SHOPPING_BAGS,
  STATUS_EMOJI.BIKINI,
  STATUS_EMOJI.EYES,
  STATUS_EMOJI.HIGH_HEEL,
  STATUS_EMOJI.WATCH,
  STATUS_EMOJI.DARK_SUNGLASSES,
  STATUS_EMOJI.LIPSTICK,
  STATUS_EMOJI.RING,
  STATUS_EMOJI.BOOKS,
  STATUS_EMOJI.MORTAR_BOARD,
  STATUS_EMOJI.STETHOSCOPE,
  STATUS_EMOJI.ROTATING_LIGHT,
  STATUS_EMOJI.FIRE_EXTINGUISHER,
  STATUS_EMOJI.HAMMER_AND_WRENCH,
  STATUS_EMOJI.CAMERA_WITH_FLASH,
  STATUS_EMOJI.COMPUTER,
  STATUS_EMOJI.MONEYBAG,
  STATUS_EMOJI.JACK_O_LANTERN,
  STATUS_EMOJI.MOUSE,
  STATUS_EMOJI.SPIDER_WEB,
  STATUS_EMOJI.BAT,
  STATUS_EMOJI.SKULL,
  STATUS_EMOJI.SPIDER,
  STATUS_EMOJI.WINE_GLASS,
  STATUS_EMOJI.BLOOD_DROP,
  STATUS_EMOJI.RAINBOW_FLAG,
  STATUS_EMOJI.TRANSGENDER_FLAG,
]

export const completeProfileSliderHiddenType = {
  SESSION: 'completeProfileSliderHiddenType/SESSION',
  USER: 'completeProfileSliderHiddenType/USER',
}

export const sliderHiddenTypeToTimeoutMap = {
  [completeProfileSliderHiddenType.SESSION]: 2 * _.d,
  [completeProfileSliderHiddenType.USER]: 2 * _.w,
}

export const SESSION_COUNT_SLIDER_VISIBLE_THRESHOLD = 3

export const UPDATE_SOCIAL_LINKS_INTERVAL = 8 * _.w
export const UPDATE_SOCIAL_LINKS_POST_REGISTRATION_THRESHOLD = 1 * _.w

export const VERIFICATION_BANNER_INTERVAL = 7 * _.d

export const userTintColors = [
  TINT_COLOR.DEFAULT,
  TINT_COLOR.LIGHT_BLUE,
  TINT_COLOR.ORANGE,
]
