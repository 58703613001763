import { getAccessToken } from '@tellonym/core/app/selectors'
import { colors } from '@tellonym/core/common/colorSystem'
import { USER_LOG_TYPE } from '@tellonym/enums/lib/User'
import { Table } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { config } from '../../../config'
import { Avatar, Box, Text } from '../../common'
import { fixTypeCasing } from '../../common/helpers'
import { fetchUserLog, refreshUserLog } from '../actions'
import { TabLogs as selector } from '../selectors'

const filterableTypes = [
  USER_LOG_TYPE.LOGIN,
  USER_LOG_TYPE.USERNAME,
  USER_LOG_TYPE.NAME,
  USER_LOG_TYPE.LANGUAGE,
  USER_LOG_TYPE.STATUS,
  USER_LOG_TYPE.INSTAGRAM,
  USER_LOG_TYPE.TWITTER,
  USER_LOG_TYPE.EMAIL,
  USER_LOG_TYPE.PASSWORD,
  USER_LOG_TYPE.PHONE,
  USER_LOG_TYPE.TELLS_ONLY_FROM_REGISTERED,
  USER_LOG_TYPE.FAILED_LOGIN,
  USER_LOG_TYPE.TYPE,
  USER_LOG_TYPE.LOCATION,
  USER_LOG_TYPE.AVATAR,
  USER_LOG_TYPE.MAIN_LANGUAGE,
  USER_LOG_TYPE.CURRENT_EXPERIMENT_ID,
  USER_LOG_TYPE.PREMIUM_UNTIL,
  USER_LOG_TYPE.THIRD_PARTY_LOGIN_INFO,
]

const TextBox = ({ children }) => (
  <Text type="note" center style={{ whiteSpace: 'nowrap' }}>
    {children}
  </Text>
)

const ChangeBox = ({ accessToken, profile, type, value }) => {
  if (
    [USER_LOG_TYPE.AVATAR, USER_LOG_TYPE.UPLOAD_ADDITIONAL_AVATAR].includes(
      type
    ) &&
    typeof value === 'string' &&
    value.includes('.jpg')
  ) {
    return (
      <Box justifyContent="center" alignItems="center" width="100%">
        <Avatar
          square
          user={
            profile.avatars.some(
              ({ avatarFileName }) => avatarFileName === value
            )
              ? { avatarFileName: value }
              : {
                  avatarFileName: `${config.api.host}/admin/user/settings/avatarhistory/${value}?access_token=${accessToken}`,
                }
          }
          size={88}
        />
      </Box>
    )
  }

  return (
    <Text type="note" center style={{ minWidth: 200 }}>
      {value}
    </Text>
  )
}

export const TabLog = ({ profile }) => {
  const dispatch = ReactRedux.useDispatch()

  const accessToken = ReactRedux.useSelector(getAccessToken)

  const {
    hasMore,
    isFetching,
    isRefreshing,
    data: baseData,
  } = ReactRedux.useSelector(selector)

  const data = baseData[profile.id]

  const dataSource = React.useMemo(
    () => data?.ids?.map((id) => data.data[id]) ?? [],
    [data]
  )

  const columns = React.useMemo(
    () => [
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: (value) => <TextBox>{USER_LOG_TYPE[value]}</TextBox>,
      },
      {
        title: 'New Value',
        dataIndex: 'newValue',
        key: 'newValue',
        render: (value, item) => (
          <ChangeBox
            accessToken={accessToken}
            profile={profile}
            type={item.type}
            value={value}
          />
        ),
      },
      {
        title: 'Old Value',
        dataIndex: 'oldValue',
        key: 'oldValue',
        render: (value, item) => (
          <ChangeBox
            accessToken={accessToken}
            profile={profile}
            type={item.type}
            value={value}
          />
        ),
      },
      {
        title: 'Time',
        dataIndex: 'time',
        key: 'time',
        render: (value) => (
          <TextBox>{dayjs(value).format('D.MM.YYYY, HH:mm')}</TextBox>
        ),
      },
      {
        title: 'Is by Moderator',
        dataIndex: 'isByModerator',
        key: 'isByModerator',
        render: (value) => <TextBox>{String(value)}</TextBox>,
      },
      {
        title: 'Agent',
        dataIndex: 'agent',
        key: 'agent',
        render: (value) => <TextBox>{value}</TextBox>,
      },
      {
        title: 'Device',
        dataIndex: 'device',
        key: 'device',
        render: (value) => <TextBox>{value}</TextBox>,
      },
      {
        title: 'OS',
        dataIndex: 'os',
        key: 'os',
        render: (value) => <TextBox>{value}</TextBox>,
      },
      {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        render: (value) => <TextBox>{value}</TextBox>,
      },
      {
        title: 'IP',
        dataIndex: 'ip',
        key: 'ip',
        render: (value) => <TextBox>{value}</TextBox>,
      },
    ],
    [accessToken, profile]
  )

  const [page, setPage] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(20)
  const [selectedFilterTypes, setSelectedFilterTypes] = React.useState([])

  const pagesCount = Math.ceil(dataSource.length / pageSize)
  const isLastPage = page === pagesCount

  React.useEffect(() => {
    dispatch(
      refreshUserLog({
        id: profile.id,
        types: selectedFilterTypes,
        limit: pageSize + 1,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.id, selectedFilterTypes])

  React.useEffect(() => {
    if (!isRefreshing && hasMore && isLastPage) {
      dispatch(
        fetchUserLog({
          id: profile.id,
          oldestId: dataSource[dataSource.length - 1].id,
          types: selectedFilterTypes,
          limit: pageSize + 1,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource.length, hasMore, isLastPage, profile.id])

  return (
    <Box gap={8}>
      <Box
        flexDirection="row"
        flexWrap="wrap"
        maxWidth={1100}
        height={80}
        gap={8}
        style={{
          overflowY: 'auto',
          overflowX: 'hidden',
        }}>
        {filterableTypes.map((type) => (
          <Box
            justifyContent="center"
            paddingVertical={5}
            paddingHorizontal={12}
            backgroundColor={
              selectedFilterTypes.includes(type)
                ? colors.black[2]
                : 'rgba(245, 245, 245, 1)'
            }
            onPress={() => {
              setSelectedFilterTypes((prev) => {
                if (prev.includes(type)) {
                  return type === USER_LOG_TYPE.AVATAR
                    ? prev.filter(
                        (t) =>
                          t !== type &&
                          t !== USER_LOG_TYPE.UPLOAD_ADDITIONAL_AVATAR
                      )
                    : prev.filter((t) => t !== type)
                }

                return type === USER_LOG_TYPE.AVATAR
                  ? [...prev, type, USER_LOG_TYPE.UPLOAD_ADDITIONAL_AVATAR]
                  : [...prev, type]
              })
            }}>
            <Text
              type="small"
              color={
                selectedFilterTypes.includes(type)
                  ? colors.white[1]
                  : colors.black[1]
              }>
              {fixTypeCasing(USER_LOG_TYPE[type])}
            </Text>
          </Box>
        ))}
      </Box>
      <Table
        dataSource={dataSource}
        columns={columns}
        loading={isRefreshing || isFetching}
        pagination={{
          current: page,
          onChange: (page) => {
            setPage(page)
          },
          onShowSizeChange: (page, size) => {
            setPage(page)
            setPageSize(size)
          },
          pageSize,
          pageSizeOptions: [10, 20, 50],
          position: ['bottomCenter'],
          showSizeChanger: true,
          total: dataSource.length,
        }}
      />
    </Box>
  )
}
