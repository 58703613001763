import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { colors } from '@tellonym/core/common/colorSystem'
import { Tooltip } from 'antd'
import React, { useMemo } from 'react'
import * as Redux from 'react-redux'
import { Icon, Text, View, styleSheets, theme } from '../../common'
import { hexWithAlpha } from '../../common/services'
import { getCompareKey } from '../selectors'
import { getDisplayValue } from '../services'

const RELEVANT_CHANGE_DELTA = 0.03

const CHANGE = {
  INCREASE: 'increase',
  DECREASE: 'decrease',
  NO_CHANGE: 'no-change',
  SMALL_DECREASE: 'small-decrease',
  SMALL_INCREASE: 'small-increase',
}

const styles = {
  changePercentage: {
    fontSize: theme.fontSizes.note,
  },
  changePercentageIcon: {
    alignSelf: 'center',
    fontSize: theme.fontSizes.note,
    marginRight: 4,
  },
  displayValue: { minWidth: 60, textAlign: 'center' },
  missingValues: {
    backgroundColor: theme.colors.veryLightYellow,
    borderRadius: 6,
  },
}

const getChange = ({ value, changePercentage }) => {
  // When no value was generated for the given day don't indicate a change
  if (
    (value > 0 && changePercentage === 0) ||
    (value === 0 && changePercentage !== 0)
  ) {
    return { status: CHANGE.NO_CHANGE }
  }

  switch (true) {
    case changePercentage >= RELEVANT_CHANGE_DELTA:
      return { status: CHANGE.INCREASE }

    case changePercentage <= -RELEVANT_CHANGE_DELTA:
      return { status: CHANGE.DECREASE }

    case changePercentage > 0:
      return { status: CHANGE.SMALL_INCREASE }

    case changePercentage < 0:
      return { status: CHANGE.SMALL_DECREASE }

    default:
      return { status: CHANGE.NO_CHANGE }
  }
}

const getChangeConfig = ({
  amountDecimals,
  value,
  compareKey,
  compareValues,
  isHigherBetter = true,
  isPercentage,
  isPrimaryValue,
}) => {
  if (typeof compareValues === 'undefined' || typeof value === 'undefined') {
    return {
      displayValue: '',
      icon: undefined,
      color: undefined,
    }
  }

  // For a percentage we want to show the percentage point difference
  const changePercentage = isPercentage
    ? (value ?? 0) - (compareValues?.[compareKey]?.value ?? 0)
    : compareValues?.[compareKey]?.percent

  const { status } = getChange({ value, changePercentage })

  const displayValue = getDisplayValue(changePercentage, true, amountDecimals)

  switch (true) {
    case status === CHANGE.DECREASE && isHigherBetter === false:
    case status === CHANGE.INCREASE && isHigherBetter: {
      const color = hexWithAlpha(colors.green[8], isPrimaryValue ? 1 : 0.5)

      return {
        color,
        icon: faCaretUp,
        displayValue,
      }
    }

    case status === CHANGE.DECREASE && isHigherBetter:
    case status === CHANGE.INCREASE && isHigherBetter === false: {
      const color = hexWithAlpha(colors.red[1], isPrimaryValue ? 1 : 0.5)

      return {
        color,
        icon: faCaretDown,
        displayValue,
      }
    }

    case status === CHANGE.SMALL_DECREASE && isHigherBetter === false:
    case status === CHANGE.SMALL_INCREASE && isHigherBetter:
      return { color: theme.colors.placeholder, icon: faCaretUp, displayValue }

    case status === CHANGE.SMALL_DECREASE && isHigherBetter:
    case status === CHANGE.SMALL_INCREASE && isHigherBetter === false:
      return {
        color: theme.colors.placeholder,
        icon: faCaretDown,
        displayValue,
      }

    default: {
      return { color: theme.colors.placeholder, icon: undefined, displayValue }
    }
  }
}

export const StatsItemCh = ({
  amountDecimals,
  isPercentage,
  isPrimaryValue,
  compareValues,
  isHigherBetter,
  value,
  warning,
}) => {
  const compareKey = Redux.useSelector(getCompareKey)
  const hasWarning = typeof warning === 'string'
  const displayValue = useMemo(
    () => getDisplayValue(value, isPercentage, amountDecimals),
    [value, isPercentage, amountDecimals]
  )

  const compareTooltipValue = useMemo(
    () => getDisplayValue(compareValues?.[compareKey]?.value, isPercentage),
    [compareKey, compareValues, isPercentage]
  )

  const changeConfig = useMemo(
    () =>
      getChangeConfig({
        value,
        compareKey,
        compareValues,
        isHigherBetter,
        isPercentage,
        isPrimaryValue,
      }),
    [
      value,
      compareKey,
      compareValues,
      isHigherBetter,
      isPercentage,
      isPrimaryValue,
    ]
  )

  const textStyle = useMemo(() => {
    const styles = { fontSize: 14, color: colors.grey[7] }

    if (isPrimaryValue) {
      return { ...styles, color: colors.black[1] }
    }

    return styles
  }, [isPrimaryValue])

  return (
    <Tooltip title={warning}>
      <View style={styleSheets.alignItems.center}>
        <Text.Ant
          ellipsis={{ tooltip: displayValue }}
          style={{
            ...(hasWarning ? styles.missingValues : {}),
            ...styles.displayValue,
            ...textStyle,
          }}>
          {displayValue}
        </Text.Ant>
        <Tooltip title={compareTooltipValue}>
          <View
            style={[
              styleSheets.flex.direction.row,
              styleSheets.justifyContent.center,
            ]}>
            {changeConfig.icon && (
              <Icon
                icon={changeConfig.icon}
                style={{
                  ...styles.changePercentageIcon,
                  color: changeConfig.color,
                }}
              />
            )}
            <Text.Ant
              ellipsis={{ tooltip: changeConfig.displayValue }}
              style={{
                ...styles.changePercentage,
                color: changeConfig.color,
              }}>
              {changeConfig.displayValue}
            </Text.Ant>
          </View>
        </Tooltip>
      </View>
    </Tooltip>
  )
}
