import { EllipsisOutlined } from '@ant-design/icons'
import { User } from '@tellonym/enums'
import { Button, Checkbox, Input, Modal } from 'antd'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { Box, styleSheets } from '../../common'
import { setHasTakenModerationAction } from '../actions'
import { useShadowBanMutation, useUndoShadowBanMutation } from '../queries'

const styles = {
  button: {
    flex: 1,
    height: 32,
  },
}

const ModalShadowBan = ({
  onChangeTextReason,
  shouldBlockSentTells,
  textReason,
  toggleShouldBlockSentTells,
}) => (
  <Box>
    <Input.TextArea
      value={textReason}
      onChange={(e) => onChangeTextReason(e.target.value)}
      placeholder="Reason for internal usage..."
      rows={4}
    />

    <Checkbox
      onChange={toggleShouldBlockSentTells}
      checked={shouldBlockSentTells}
      style={styleSheets.margin.top[24]}>
      Block all sent tells
    </Checkbox>
  </Box>
)

export const ShadowBanButtons = ({ profile, report }) => {
  const dispatch = ReactRedux.useDispatch()

  const [isModalVisible, setIsModalVisible] = React.useState(false)
  const [shouldBlockSentTells, setShouldBlockSentTells] = React.useState(false)
  const [textReason, setTextReason] = React.useState('')

  const isShadowBanned = [
    User.USER_TYPE.BAN_SHADOW,
    User.USER_TYPE.BAN_SHADOW_AUTOMATIC,
  ].includes(profile?.type)

  const { mutate: triggerShadowBan } = useShadowBanMutation()

  const { mutate: triggerUndoShadowBan } = useUndoShadowBanMutation()

  const shadowBan = () => {
    dispatch(setHasTakenModerationAction(true))
    setIsModalVisible(false)

    triggerShadowBan({
      userId: report?.user?.id ?? profile?.id,
      reportId: report?.id,
      shouldBlockSentTells,
      textReason,
    })
  }

  const shadowBanAndBlockAll = () => {
    dispatch(setHasTakenModerationAction(true))

    triggerShadowBan({
      userId: report?.user?.id ?? profile?.id,
      reportId: report?.id,
      shouldBlockSentTells: true,
      shouldBlockComments: true,
      shouldBlockLikes: true,
      textReason: '',
    })
  }

  const undoShadowBan = () => {
    triggerUndoShadowBan({
      userId: report?.user?.id ?? profile?.id,
      reportId: report?.id,
      reportType: report?.type,
    })
  }

  const openShadowBanModal = () => {
    setIsModalVisible(true)
  }

  const closeShadowBanModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Modal
        open={isModalVisible}
        onOk={shadowBan}
        onCancel={closeShadowBanModal}
        title="Shadow Ban"
        width="75%">
        <ModalShadowBan
          onChangeTextReason={setTextReason}
          shouldBlockSentTells={shouldBlockSentTells}
          textReason={textReason}
          toggleShouldBlockSentTells={() =>
            setShouldBlockSentTells((prev) => !prev)
          }
        />
      </Modal>
      {isShadowBanned ? (
        <Button shape="round" onClick={undoShadowBan}>
          Undo Shadow Ban
        </Button>
      ) : (
        <Box gap={8} flex={1} transparent maxWidth="100%" width="100%">
          <Box flexDirection="row" transparent>
            <Button shape="round" onClick={shadowBan} style={styles.button}>
              Shadow Ban
            </Button>

            <Button
              shape="circle"
              onClick={openShadowBanModal}
              icon={<EllipsisOutlined />}
              style={styleSheets.margin.left[8]}
            />
          </Box>

          <Button
            shape="round"
            onClick={shadowBanAndBlockAll}
            style={styles.button}>
            Shadow Ban + Block All Content
          </Button>
        </Box>
      )}
    </>
  )
}
