import { TINT_COLOR } from '@tellonym/enums/lib/User'
import { curry } from 'ramda'

export const colors = {
  white: {
    1: '#ffffff',
  },
  black: {
    1: '#14171A',
    2: '#4E5358',
    3: '#3E4245',
    4: '#2C3033',
    5: '#222529',
    6: '#1A1D21',
  },
  grey: {
    1: '#EDEFF5',
    2: '#F5F5F7',
    3: '#F9F9FA',
    4: '#EBEBED',
    5: '#CECED2',
    6: '#88898F',
    7: '#AFAFB3',
    8: 'rgba(245, 245, 247, 0.72)',
    9: '#808CA5',
    10: '#C5C7C7',
    11: '#B5B3C3',
    12: '#7A7A7A3D',
  },
  turquoise: {
    1: '#39B8AD',
    2: '#088378',
    3: '#DFFFFC',
    4: '#D7F1EF',
    5: '#25444B',
    6: 'rgba(223, 255, 252, 0.72)',
    7: '#2BC1BA',
    8: 'rgba(43, 193, 186, 0.75)',
    9: '#ECFFFD',
    10: '#B5E2DF',
  },
  pink: {
    1: '#FF005A',
    2: '#D21E60',
    3: '#FF62AD',
    4: '#533244',
    5: '#722644',
    6: '#3E2633',
    7: '#FF3C80',
    8: '#F36BC1',
    9: 'rgba(255, 0, 90, 0.7)',
    10: 'rgba(243, 107, 193, 0.8)',
    12: '#F76C81',
  },
  rose: {
    1: '#FFE4F1',
    2: '#FFF4F9',
    3: '#FFF1F8',
    4: 'rgba(255, 1, 123, 0.2)',
    5: '#FCC8D9',
    6: '#FF85FF',
  },
  navy: {
    1: '#1C293F',
    2: '#24334B',
    3: '#3F4F6A',
    4: '#8B96A8',
    5: 'rgba(92, 120, 163, 0.2)',
    6: '#313D52',
    7: '#474D56',
    8: '#1B2330',
    9: '#2B3749',
    10: '#202733',
    11: 'rgba(36, 51, 75, 0.72)',
    12: '#2F343F',
    13: '#222C38',
  },
  blue: {
    1: '#22BBEB',
    2: '#4F7AE8',
    3: '#6C95FF',
    4: '#386CF5',
    5: '#264AA7',
    6: '#56BEF6',
    7: '#007AFF',
    8: '#1A73E8',
    9: '#35A6FF',
    10: 'rgba(53, 166, 255, 0.75)',
    11: '#355BE3',
    12: 'rgba(53, 91, 227, 0.8)',
    14: '#E5F3FF',
    15: '#9FD4FF',
    16: '#F3F6FF',
  },
  red: {
    1: '#EA3E33',
    2: 'rgba(234, 62, 51, 0.5)',
    3: '#B81C4D',
    4: '#FFE5E3',
    5: 'rgba(234, 62, 51, 0.8)',
    6: '#FFF7F6',
  },
  orange: {
    1: '#FE6600',
    2: '#FD9B63',
    3: '#FEAC34',
    4: 'rgba(254, 172, 52, 0.75)',
    5: '#F0690E',
    6: 'rgba(240, 105, 14, 0.75)',
  },
  transparent: {
    0: 'transparent',
    1: 'rgba(18, 18, 19, 0.2)',
    2: 'rgba(190, 190, 196, 0.2)',
    3: 'rgba(245, 245, 247, 0.3)',
    4: 'rgba(75, 75, 83, 0.4)',
    5: 'rgba(16, 16, 18, 0.4)',
  },
  socials: {
    // TODO: add gradient colors in a useable way
    snapchat: {
      4: '#FFFC00',
      5: '#FCF07E',
    },
    twitter: {
      3: '#000000',
    },
  },
  mustard: {
    1: '#FFFEF4',
    2: '#FBF7DA',
    3: '#D7C633',
    4: '#3B3E31',
    5: '#847C38',
    6: '#D5C647',
    7: '#ECEE91',
  },
  fuscha: {
    1: '#FFF7FC',
    2: '#FDE8F4',
    3: '#CB4897',
    4: '#8B5174',
    5: '#43273F',
    6: '#D75EA7',
    7: '#362240',
  },
  purple: {
    1: '#FCF4FF',
    2: '#F8E5FF',
    3: '#BD67DF',
    4: '#7D5490',
    5: '#3E2D51',
    6: '#C65CF7',
  },
  hibiscus: {
    1: '#FFF4F6',
    2: '#FFE5E8',
    3: '#DF6776',
    4: '#895259',
    5: '#3D252F',
    6: '#D0616F',
  },
  carrot: {
    1: '#FFF8F4',
    2: '#FFEFE5',
    3: '#E88045',
    4: '#82563E',
    5: '#43322C',
    6: '#D97455',
  },
  violet: {
    1: '#F5F4FF',
    2: '#E8E5FF',
    3: '#7167DF',
    4: '#56518F',
    5: '#2A2656',
    6: '#9086FF',
    7: '#763CBF',
    8: '#C7CAE4',
    9: '#4F1BE5',
    10: 'rgba(113, 103, 223, 0.75)',
    11: '#EEEDFA',
    12: '#25293C',
    13: '#7D4FFF',
    14: 'rgba(85, 38, 219, 0.8)',
    15: '#AA89F2',
  },
  saphire: {
    1: '#F4F8FF',
    2: '#DAE6FF',
    3: '#4A7FE6',
    4: '#4A5F8B',
    5: '#24324C',
    6: '#5C91FF',
    7: '#27307C',
  },
  green: {
    1: '#F4FFFB',
    2: '#DCFBEE',
    3: '#32C587',
    4: '#243C3B',
    5: '#2B7253',
    6: '#2EC085',
    7: '#C8FFA4',
    8: '#46CD25',
    9: '#1BB269',
    10: '#82BF35',
    11: 'rgba(130, 191, 53, 0.75)',
    12: 'rgba(27, 178, 105, 0.75)',
    13: '#E1F0CF',
    14: '#304319',
    15: '#26B97B',
    16: '#7CFFC8',
    17: '#F4FCEA',
    18: '#B2ECD0',
    19: 'rgba(36, 135, 92, 0.8)',
    20: '#63C76F',
  },
  yellow: {
    1: '#FFDA52',
    2: '#FFF7EB',
    3: '#FFD9A2',
  },
  modcp: {
    1: '#E6F7FF',
    2: '#1890FF',
    3: '#FAFAFA',
  },
  gradient: {
    branding: { 0: ['#FF005A', '#FE566A'] },
    dark: { 5: ['#000', '#797979'], 15: ['#f1f4ff', '#b3cdfa'] },
  },
}

export const premiumColors = {
  [TINT_COLOR.DEFAULT]: {
    default: colors.pink[1],
    banner: colors.pink[7],
    transparent: {
      20: 'rgba(255, 0, 90, 0.2)',
      40: 'rgba(255, 0, 90, 0.4)',
    },
    input: colors.rose[2],
    disabled: colors.rose[5],
    background: { light: colors.rose[2], dark: colors.pink[4] },
  },
  [TINT_COLOR.LIGHT_BLUE]: {
    default: colors.blue[9],
    banner: colors.blue[9],
    transparent: {
      20: 'rgba(53, 166, 255, 0.2)',
      40: 'rgba(53, 166, 255, 0.4)',
    },
    input: colors.blue[14],
    disabled: colors.blue[15],
    background: { light: colors.blue[16], dark: colors.saphire[5] },
  },
  [TINT_COLOR.ORANGE]: {
    default: colors.orange[3],
    banner: colors.orange[3],
    transparent: {
      20: 'rgba(254, 172, 52, 0.2)',
      40: 'rgba(254, 172, 52, 0.4)',
    },
    input: colors.yellow[2],
    disabled: colors.yellow[3],
    background: { light: colors.yellow[2], dark: colors.carrot[5] },
  },
  [TINT_COLOR.RED]: {
    default: colors.red[1],
    banner: colors.red[1],
    transparent: {
      20: 'rgba(234, 62, 51, 0.2)',
      40: 'rgba(234, 62, 51, 0.4)',
    },
    input: colors.red[6],
    disabled: colors.rose[5],
    background: { light: colors.red[6], dark: colors.pink[4] },
  },
  [TINT_COLOR.GREEN]: {
    default: colors.green[10],
    banner: colors.green[10],
    transparent: {
      20: 'rgba(130, 191, 53, 0.2)',
      40: 'rgba(130, 191, 53, 0.4)',
    },
    input: colors.green[17],
    disabled: colors.green[13],
    background: { light: colors.green[17], dark: colors.green[14] },
  },
  [TINT_COLOR.PURPLE]: {
    default: colors.violet[3],
    banner: colors.violet[3],
    transparent: {
      20: 'rgba(113, 103, 223, 0.2)',
      40: 'rgba(113, 103, 223, 0.4)',
    },
    input: colors.violet[1],
    disabled: colors.violet[8],
    background: { light: colors.violet[1], dark: colors.violet[5] },
  },
  [TINT_COLOR.DARK_ORANGE]: {
    default: colors.orange[5],
    banner: colors.orange[5],
    transparent: {
      20: 'rgba(240, 105, 14, 0.2)',
      40: 'rgba(240, 105, 14, 0.4)',
    },
    input: colors.carrot[1],
    disabled: colors.hibiscus[2],
    background: { light: colors.carrot[1], dark: colors.carrot[5] },
  },
  [TINT_COLOR.DARK_GREEN]: {
    default: colors.green[9],
    banner: colors.green[9],
    transparent: {
      20: 'rgba(27, 178, 105, 0.2)',
      40: 'rgba(27, 178, 105, 0.4)',
    },
    input: colors.green[1],
    disabled: colors.green[18],
    background: { light: colors.green[1], dark: colors.green[4] },
  },
  [TINT_COLOR.PINK]: {
    default: colors.pink[8],
    banner: colors.pink[8],
    transparent: {
      20: 'rgba(243, 107, 193, 0.2)',
      40: 'rgba(243, 107, 193, 0.4)',
    },
    input: colors.rose[2],
    disabled: colors.rose[5],
    background: { light: colors.rose[2], dark: colors.pink[4] },
  },
  [TINT_COLOR.BLUE]: {
    default: colors.blue[11],
    banner: colors.blue[11],
    transparent: {
      20: 'rgba(53, 91, 227, 0.2)',
      40: 'rgba(53, 91, 227, 0.4)',
    },
    input: colors.blue[16],
    disabled: colors.navy[5],
    background: { light: colors.blue[16], dark: colors.saphire[5] },
  },
  [TINT_COLOR.TURQUOISE]: {
    default: colors.turquoise[7],
    banner: colors.turquoise[7],
    transparent: {
      20: 'rgba(43, 193, 186, 0.2)',
      40: 'rgba(43, 193, 186, 0.4)',
    },
    input: colors.turquoise[9],
    disabled: colors.turquoise[10],
    background: { light: colors.turquoise[9], dark: colors.turquoise[5] },
  },
}

/**
 * In components always use useThemedColor to cause a rerender on Dark Mode toggle
 */
export const getThemedColor = curry((theme, lightColor, darkColor) => {
  if (theme === 'dark') {
    return darkColor
  }

  return lightColor
})
