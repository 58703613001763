import { User } from '@tellonym/enums'
import { Radio } from 'antd'
import React, { useEffect } from 'react'
import * as Redux from 'react-redux'
import { getPermissions } from '../../app/selectors'
import { Alert, ContentContainer, helpers, View } from '../../common'
import { useQueryParams } from '../../common/hooks'
import { useProfileSettingsQuery } from '../../profile/queries'
import { GeneralUserInfos } from './GeneralUserInfos'
import { TabAnalytics } from './TabAnalytics'
import { TabAnswers } from './TabAnswers'
import { TabComments } from './TabComments'
import { TabDevices } from './TabDevices'
import { TabInfo } from './TabInfo'
import { TabLog } from './TabLog'
import { TabSentTells } from './TabSentTells'
import { TabSettings } from './TabSettings'
import { TabTells } from './TabTells'

const { USER_TYPE } = User

const stages = {
  INFO: 'Info',
  TELLS: 'Tells',
  ANSWERS: 'Answers',
  SENT_TELLS: 'Sent Tells',
  COMMENTS: 'Comments',
  ANALYTICS: 'Analytics',
  DEVICES: 'Devices',
  LOG: 'Log',
  SETTINGS: 'Settings',
}

const stageOptions = Object.values(stages).map((value) => ({
  label: value,
  value,
}))

const defaultQueryParams = {
  stage: stages.TELLS,
}

const adminTabsBlacklist = [
  stages.TELLS,
  stages.ANSWERS,
  stages.SENT_TELLS,
  stages.LOG,
]

const NotAllowed = () => {
  useEffect(() => {
    Alert.alert('Not Allowed', "You can't access Sent Tells from admins")
  }, [])
  return null
}

const getRoutes = ({ profile, permissions }) => {
  const hasSeeAdminContentPermission = helpers.checkPermission(
    'seeadmincontent',
    permissions,
    false
  )

  const routes = {
    [stages.INFO]: <TabInfo profile={profile} />,
    [stages.TELLS]: <TabTells profile={profile} />,
    [stages.ANSWERS]: <TabAnswers profile={profile} />,
    [stages.SENT_TELLS]: <TabSentTells profile={profile} />,
    [stages.COMMENTS]: <TabComments profile={profile} />,
    [stages.ANALYTICS]: <TabAnalytics profile={profile} />,
    [stages.DEVICES]: <TabDevices profile={profile} />,
    [stages.LOG]: <TabLog profile={profile} />,
    [stages.SETTINGS]: <TabSettings profile={profile} />,
  }

  if (profile.type === USER_TYPE.ADMIN && !hasSeeAdminContentPermission) {
    adminTabsBlacklist.forEach((tab) => {
      routes[tab] = <NotAllowed />
    })
  }

  return routes
}

export const PageProfile = ({ profile }) => {
  const [queryParams, setQueryParams] = useQueryParams(defaultQueryParams)
  const permissions = Redux.useSelector(getPermissions)

  const routes = React.useMemo(
    () => getRoutes({ profile, permissions }),
    [permissions, profile]
  )

  const onChangeTab = React.useCallback(
    (e) => {
      setQueryParams({ stage: e.target.value })
    },
    [setQueryParams]
  )

  const { isFetching } = useProfileSettingsQuery({
    userId: profile.id,
  })

  return (
    <ContentContainer isRefreshing={isFetching}>
      <Radio.Group
        optionType="button"
        options={stageOptions}
        onChange={onChangeTab}
        value={queryParams.stage}
        size="large"
        style={{ alignSelf: 'center', marginTop: 24, marginBottom: 24 }}
      />
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          padding: 16,
        }}>
        <GeneralUserInfos profile={profile} />
        {routes[queryParams.stage]}
      </View>
    </ContentContainer>
  )
}
