import { identity, pick } from 'ramda'
import { events } from '../events'
import {
  REFRESH_ONBOARDING_RECOMMENDATIONS_ERROR,
  REFRESH_ONBOARDING_RECOMMENDATIONS_SUCCESS,
} from '../people/types'
import { SET_PHONE, SET_PHONE_ERROR } from '../profile/types'
import { TYPE, authScreensToPersist } from './constants'
import * as t from './types'

export { name } from './constants'

export const initialState = {
  accessToken: undefined,
  captcha: undefined,
  currentAuthScreen: undefined,
  deviceCheckToken: undefined,
  email: '',
  hasAcceptedTerms: false,
  hasLoggedIn: false,
  hasPromptedPushPermission: false,
  isAuthConnectPhoneBusy: false,
  isBanned: false,
  isBusy: false,
  isEmailAvailable: undefined,
  isRefreshingBannedStatus: false,
  isUsernameAvailable: undefined,
  isValidLogin: undefined,
  lang: undefined,
  loginFailureCount: 0,
  password: '',
  resetToken: undefined,
  service: undefined,
  shouldShowAtt: undefined,
  shouldShowCmp: undefined,
  shouldShowGoogleIdentityServiceButton: false,
  totalCaptchasShownCount: 0,
  userId: undefined,
  username: '',
}

const persistKeys = ['currentAuthScreen', 'hasLoggedIn']

export const persistence = {
  in: pick(persistKeys),
  out: identity,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case t.AUTHENTICATE:
      return {
        ...state,
        service: action.payload.service,
      }

    case t.ABORT_REGISTRATION:
    case t.LOGIN_LEGACY:
    case t.LOGIN_WITH_APPLE:
    case t.LOGIN_WITH_GOOGLE:
    case t.REGISTER_WITH_APPLE:
    case t.REGISTER_WITH_GOOGLE:
    case t.REQUEST_PASSWORD:
    case t.RESET_PASSWORD:
      return { ...state, isBusy: true }

    case t.REGISTER:
      return {
        ...state,
        hasLoggedIn: false,
        isBusy: true,
        isValidLogin: undefined,
      }

    case t.ABORT_REGISTRATION_ERROR:
    case t.ABORT_REGISTRATION_SUCCESS:
    case t.CHECK_USERNAME_AVAILABILITY_ERROR:
    case t.REQUEST_PASSWORD_ERROR:
    case t.REQUEST_PASSWORD_SUCCESS:
    case t.RESET_PASSWORD_ERROR:
    case t.RESET_PASSWORD_SUCCESS:
      return { ...state, isBusy: false }

    case t.CHANGE_EMAIL:
      return {
        ...state,
        email: action.payload,
        isEmailAvailable: undefined,
      }

    case t.CHANGE_PASSWORD:
      return {
        ...state,
        password: action.payload,
      }

    case t.CHANGE_STATE:
      return {
        ...state,
        ...action.payload,
      }

    case t.CHANGE_USERNAME:
      return {
        ...state,
        username: action.payload,
      }

    case t.CHECK_EMAIL_AVAILABILITY:
      return {
        ...state,
        isBusy: true,
        isEmailAvailable: undefined,
      }

    case t.CHECK_EMAIL_AVAILABILITY_ERROR:
      return {
        ...state,
        isBusy: false,
        captcha: undefined,
      }

    case t.CHECK_EMAIL_AVAILABILITY_SUCCESS:
      return {
        ...state,
        isBusy: false,
        isEmailAvailable: !!action.payload.email,
      }

    case t.CHECK_USERNAME_AVAILABILITY:
      return {
        ...state,
        isBusy: true,
        isUsernameAvailable: undefined,
      }

    case t.CHECK_USERNAME_AVAILABILITY_SUCCESS:
      return {
        ...state,
        isBusy: false,
        isUsernameAvailable: !!action.payload.username,
        username:
          !action.payload.username && action.payload.suggestion
            ? action.payload.suggestion
            : state.username,
      }

    case t.LOGIN:
      return {
        ...state,
        isBusy: true,
        isValidLogin: undefined,
      }

    case t.LOGIN_ERROR:
      return {
        ...state,
        captcha: undefined,
        isBusy: false,
        loginFailureCount: state.loginFailureCount + 1,
      }

    case t.LOGIN_SUCCESS:
      return {
        ...state,
        hasLoggedIn: true,
        isBusy: false,
        password: undefined,
      }

    case t.LOGIN_LEGACY_ERROR:
    case t.LOGIN_WITH_APPLE_ERROR:
    case t.LOGIN_WITH_GOOGLE_ERROR:
    case t.REGISTER_WITH_APPLE_ERROR:
    case t.REGISTER_WITH_GOOGLE_ERROR:
      return {
        ...state,
        isBusy: false,
        loginFailureCount: state.loginFailureCount + 1,
      }

    case t.LOGIN_LEGACY_SUCCESS:
    case t.REGISTER_WITH_APPLE_SUCCESS:
    case t.REGISTER_WITH_GOOGLE_SUCCESS:
    case t.LOGIN_WITH_APPLE_SUCCESS:
    case t.LOGIN_WITH_GOOGLE_SUCCESS:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        hasLoggedIn:
          [
            t.LOGIN_LEGACY_SUCCESS,
            t.LOGIN_WITH_APPLE_SUCCESS,
            t.LOGIN_WITH_GOOGLE_SUCCESS,
          ].includes(action.type) || action.payload.type === TYPE.LOGIN,
        isBusy: false,
        lang: action.payload.lang,
        resetToken: action.payload.resetToken,
        userId: action.payload.userId,
      }

    case t.REGISTER_ERROR:
      return {
        ...state,
        captcha: undefined,
        isBusy: false,
      }

    case t.REGISTER_SUCCESS: {
      return {
        ...state,
        hasLoggedIn: false,
        isBusy: false,
        password: undefined,
      }
    }

    case t.RESET_AUTHENTICATION:
      return {
        ...state,
        captcha: undefined,
      }

    case t.SYNC_DEVICE_BAN_INFORMATION:
      return {
        ...state,
        deviceCheckToken: action.payload.deviceToken,
      }

    case t.SYNC_DEVICE_BAN_INFORMATION_ERROR:
      return {
        ...state,
        deviceCheckToken: undefined,
      }

    case t.SET_HAS_ACCEPTED_TERMS:
      return {
        ...state,
        hasAcceptedTerms: action.payload,
      }

    case t.SET_HAS_PROMPTED_PUSH_PERMISSION:
      return {
        ...state,
        hasPromptedPushPermission: action.payload,
      }

    case t.SET_SHOULD_SHOW_GOOGLE_IDENTITY_SERVICE_BUTTON:
      return {
        ...state,
        shouldShowGoogleIdentityServiceButton: action.payload,
      }

    case SET_PHONE: {
      return {
        ...state,
        isAuthConnectPhoneBusy: true,
      }
    }

    case REFRESH_ONBOARDING_RECOMMENDATIONS_ERROR:
    case REFRESH_ONBOARDING_RECOMMENDATIONS_SUCCESS:
    case SET_PHONE_ERROR: {
      return {
        ...state,
        isAuthConnectPhoneBusy: false,
      }
    }

    case events.NAVIGATION: {
      if (action.meta.setup.isOnboardingCompleted) {
        if (state.currentAuthScreen) {
          return {
            ...state,
            currentAuthScreen: undefined,
          }
        }

        return state
      }

      const shouldPersistCurrentScreen = authScreensToPersist.includes(
        action.payload.currentRoute.name
      )

      if (
        shouldPersistCurrentScreen &&
        state.currentAuthScreen !== action.payload.currentRoute.name
      ) {
        return {
          ...state,
          currentAuthScreen: action.payload.currentRoute.name,
        }
      }

      return state
    }

    default:
      return state
  }
}
