import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FlatList } from '../../common'
import { fetchAnswers, refreshAnswers } from '../actions'
import { TabAnswers as selector } from '../selectors'
import { ContentItem } from './ContentItem'
import { HeaderSelectionActions } from './HeaderSelectionActions'

class TabAnswersComponent extends React.Component {
  state = {
    searchPhrase: '',
    shouldShowOnlyPostsWithMedia: false,
    shouldTranslate: false,
  }

  componentDidMount() {
    const { actions, profile } = this.props

    actions.refresh({
      userId: profile.id,
      onlyPostsWithMedia: this.state.shouldShowOnlyPostsWithMedia,
    })
  }

  _onChangeSearchInput = (searchPhrase) => {
    this.setState({ searchPhrase })
  }

  _onPressSearch = () => {
    const { shouldShowOnlyPostsWithMedia, searchPhrase } = this.state
    const { actions, profile } = this.props

    actions.refresh({
      onlyPostsWithMedia: shouldShowOnlyPostsWithMedia,
      searchPhrase,
      userId: profile.id,
    })
  }

  _toggleOnlyPostsWithMedia = () => {
    const { actions, profile } = this.props

    actions.refresh({
      onlyPostsWithMedia: !this.state.shouldShowOnlyPostsWithMedia,
      searchPhrase:
        this.state.searchPhrase?.length > 0
          ? this.state.searchPhrase
          : undefined,
      userId: profile.id,
    })

    this.setState((state) => ({
      shouldShowOnlyPostsWithMedia: !state.shouldShowOnlyPostsWithMedia,
    }))
  }

  _toggleTranslate = () => {
    this.setState((state) => ({ shouldTranslate: !state.shouldTranslate }))
  }

  _renderHeader = () => {
    const { data, profile } = this.props
    const { searchPhrase } = this.state

    const hasItems =
      data?.[profile.id]?.ids?.length > 0 ||
      this.state.shouldShowOnlyPostsWithMedia

    return (
      <HeaderSelectionActions
        hasItems={hasItems}
        onChangeSearchInput={this._onChangeSearchInput}
        onPressSearch={this._onPressSearch}
        profile={profile}
        searchPhrase={searchPhrase}
        shouldShowOnlyPostsWithMedia={this.state.shouldShowOnlyPostsWithMedia}
        shouldTranslate={this.state.shouldTranslate}
        toggleOnlyPostsWithMedia={this._toggleOnlyPostsWithMedia}
        toggleTranslate={this._toggleTranslate}
        type="answers"
      />
    )
  }

  render() {
    return (
      <FlatList
        _rerenderItem={this.props._rerenderItem}
        actions={{
          fetch: (payload) =>
            this.props.actions.fetch({
              userId: this.props.profile.id,
              onlyPostsWithMedia: this.state.shouldShowOnlyPostsWithMedia,
              ...payload,
            }),
          refresh: (payload) =>
            this.props.actions.refresh({
              userId: this.props.profile.id,
              onlyPostsWithMedia: this.state.shouldShowOnlyPostsWithMedia,
              ...payload,
            }),
        }}
        component={ContentItem}
        extraData={{
          hasMore: this.props.hasMore,
          isFetching: this.props.isFetching,
          isRefreshing: this.props.isRefreshing,
        }}
        extraProps={{
          type: 'answers',
          shouldTranslate: this.state.shouldTranslate,
          userId: this.props.profile.id,
        }}
        hasMore={this.props.hasMore}
        isFetching={this.props.isFetching}
        isRefreshing={this.props.isRefreshing}
        items={this.props.data[this.props.profile.id] || { ids: [], data: {} }}
        renderHeader={this._renderHeader}
        style={{ height: window.innerHeight - 171, minWidth: 700 }}
      />
    )
  }
}

const mapStateToProps = (state) => ({ ...selector(state) })
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { fetch: fetchAnswers, refresh: refreshAnswers },
    dispatch
  ),
})

export const TabAnswers = connect(
  mapStateToProps,
  mapDispatchToProps
)(TabAnswersComponent)
