import { colors } from '@tellonym/core/common/colorSystem'
import React from 'react'
import { Box, Modal, Text, theme } from '../../common'

const ModalActionDescriptionsComponent = ({ modalId }) => {
  const closeModal = () => {
    Modal.hide({ id: modalId })
  }

  return (
    <Box
      flex={1}
      alignItems="center"
      justifyContent="center"
      paddingHorizontal={64}
      transparent>
      <Modal.Body
        style={{
          backgroundColor: theme.colors.background,
          borderRadius: 25,
          padding: 32,
          paddingHorizontal: 32,
        }}>
        <Box gap={16}>
          <Box gap={16}>
            {[
              {
                button: 'Took action:',
                description:
                  'Sends information to report creator that we appreciate his report and that we did something about it. When deleting a comment, we instantly inform the comment creator with a report notification.',
              },
              {
                button: 'OKAY:',
                description:
                  'Informs the report creator that he mistakenly reported.',
              },
              {
                button: 'Shadow ban:',
                description:
                  'Set user type to SHADOW_BAN, closes report but does hot inform user, recounts followings, deletes likes, if you want to block sent tells as well use dots menu on the right',
              },
              {
                button: 'Undo Shadow ban:',
                description:
                  'Update user type to normal, closes report but does not inform user, recount followings',
              },
              {
                button: 'Quick Ban Sexual:',
                description: 'Deletes all pics, bans for 1 day, closes report',
              },
              {
                button: 'Quick 7d Ban:',
                description: 'Bans for 7 day, closes report',
              },
              {
                button: 'Quick Perma Ban Sexual:',
                description: 'Deletes all pics, bans forever, closes report',
              },
            ].map(({ button, description }) => (
              <Text key={button}>
                <Text bold>{button}</Text> {description}
              </Text>
            ))}
          </Box>

          <Box
            borderTopColor={colors.grey[6]}
            borderTopStyle="solid"
            borderTopWidth={1}
            alignItems="center"
            onPress={closeModal}
            paddingTop={16}
            width="100%">
            <Text bold>OK</Text>
          </Box>
        </Box>
      </Modal.Body>
    </Box>
  )
}

const show = (payload) =>
  Modal.show({
    render: (props) => (
      <ModalActionDescriptionsComponent {...payload} {...props} />
    ),
    hasBackdrop: true,
    shouldHideOnBackgroundPress: true,
  })

export const ModalActionDescriptions = { show }
