import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  COHORTS_CH,
  countriesToTrack,
  INTERVAL_TYPE,
} from '@tellonym/enums/lib/Stats'
import { Button, Dropdown, Menu, Modal, Select, Switch } from 'antd'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Helmet from 'react-helmet'
import * as Redux from 'react-redux'
import { getIsSidemenuShown } from '../../app/selectors'
import { Alert, moment, styleSheets, Text, theme, View } from '../../common'
import {
  convertArrayToOptions,
  getStringsFromEnums,
} from '../../common/helpers'
import { useQueryParams } from '../../common/hooks'
import { SideMenu } from '../../navigation/components/SideMenu'
import { changeCompareKey } from '../actions'
import { comparisonIntervalOptions, dateTypes } from '../constants'
import { useSelectedShortnames } from '../hooks'
import {
  getTimeoutAmount,
  useDiffMutation,
  useGenerateCohortMutation,
} from '../queries'
import { getCompareKey } from '../selectors'
import {
  dateTypeToTimespan,
  downloadCsv,
  snakeToCamelCase,
  transformDiffDataToArray,
} from '../services'
import { InputQueryParam } from './InputQueryParam'
import { ShortNamesSelector } from './ShortNamesSelector'
import { StatsTableCh } from './StatsTableCh'
import { TimeFramePickerQueryParam } from './TimeFramePicker'

const defaultQueryParams = {
  dateType: dateTypes.LAST_21_DAYS,
  startDate: undefined,
  endDate: undefined,
  countryCode: 'DE',
  experimentId: undefined,
  intervalType: INTERVAL_TYPE.DAILY,
  shortNames: [],
  cohort: undefined,
}

const styles = {
  compareSelector: { width: 130, marginLeft: 12 },
  headerContainer: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    marginTop: 24,
    marginBottom: 12,
  },
}

const RefreshMenu = ({ onPress }) => {
  return (
    <Menu onClick={onPress}>
      <Menu.Item key="generate">Generate all for cohort</Menu.Item>
    </Menu>
  )
}

const cohortOptions = convertArrayToOptions(getStringsFromEnums(COHORTS_CH))

const countryOptions = convertArrayToOptions(countriesToTrack)

const Header = React.memo(
  ({
    hasData,
    hasSetRequiredValues,
    isDisabled,
    isLoading,
    onChangeAutoRefresh,
    onChangeCompareKey,
    onPressDownloadCsv,
    onPressGenerateCohort,
    onPressRefresh,
  }) => {
    const onMenuClick = React.useCallback(
      ({ key }) => {
        if (key === 'generate') {
          onPressGenerateCohort()
        }
      },
      [onPressGenerateCohort]
    )

    return (
      <View style={{ position: 'relative', marginBottom: 24 }}>
        <View style={styles.headerContainer}>
          <View style={styleSheets.flex[1]}>
            <Text bold type="h1" style={styleSheets.margin.bottom[24]}>
              Statistic Changes
            </Text>

            <View style={styleSheets.margin.left[12]}>
              <TimeFramePickerQueryParam
                defaultQueryParams={defaultQueryParams}
                isLoading={isDisabled}
              />

              <InputQueryParam
                defaultQueryParams={defaultQueryParams}
                isOptional
                isDisabled={isDisabled}
                queryParamName="experimentId"
                text="Experiment Id"
                validateValue={(value) => Number.isInteger(Number(value))}
              />

              <InputQueryParam
                defaultQueryParams={defaultQueryParams}
                options={cohortOptions}
                isOptional
                isDisabled={isDisabled}
                queryParamName="cohort"
                text="Cohort"
              />

              <InputQueryParam
                defaultQueryParams={defaultQueryParams}
                options={countryOptions}
                isDisabled={isDisabled}
                queryParamName="countryCode"
                text="Country"
              />
            </View>
          </View>

          <View
            style={[
              styleSheets.flex[2],
              styleSheets.margin.top[24],
              styleSheets.margin.left[12],
            ]}>
            <ShortNamesSelector isDisabled={isDisabled} />
          </View>
        </View>
        <View style={styleSheets.flex.direction.row}>
          <View
            style={[
              styleSheets.flex.direction.row,
              styleSheets.flex[1],
              styleSheets.margin.left[24],
            ]}>
            <Button
              disabled={!hasData || !hasSetRequiredValues || isDisabled}
              type="dashed"
              onClick={onPressDownloadCsv}
              style={styles.buttonCsv}>
              Download .csv
            </Button>

            <Select
              disabled={!hasSetRequiredValues || isDisabled}
              placeholder="Compare with"
              style={styles.compareSelector}
              options={comparisonIntervalOptions}
              onChange={onChangeCompareKey}
            />
          </View>

          <View style={[styleSheets.alignItems.center, styleSheets.flex[3]]}>
            <Dropdown.Button
              type="primary"
              shape="round"
              loading={isDisabled || isLoading}
              onClick={onPressRefresh}
              menu={{
                items: [{ label: 'Generate all for cohort', key: 'generate' }],
                onClick: onMenuClick,
              }}>
              Refresh
            </Dropdown.Button>
          </View>

          <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
            <Switch defaultChecked onChange={onChangeAutoRefresh} />
            <Text type="small" style={{ marginLeft: 8 }}>
              Auto refresh
            </Text>
          </View>
        </View>
      </View>
    )
  }
)

const getHeaderData = ({ metrics, intervalType }) => {
  if (Number(intervalType) === INTERVAL_TYPE.WEEKLY) {
    // format of week is YEAR-WEEK
    return metrics.map((week) => {
      const [_, weekNumber] = week.split('-')

      return {
        id: week,
        metric: `Week ${weekNumber}`,
        submetric: moment().week(weekNumber).day(1).format('MMM'),
      }
    })
  }

  return metrics.map((date) => ({
    id: date,
    metric: moment(date).format('DD.MM.YY'),
    submetric: moment(date).format('ddd'),
  }))
}

const PageChDiff = () => {
  const interval = React.useRef(null)
  const compareKey = Redux.useSelector(getCompareKey)

  const dispatch = Redux.useDispatch()

  const isSidemenuShown = Redux.useSelector(getIsSidemenuShown)
  const [queryParams] = useQueryParams(defaultQueryParams)
  const [isAutoRefreshEnabled, setIsAutoRefreshEnabled] = React.useState(true)

  const selectedShortnames = useSelectedShortnames()

  const hasSetRequiredValues =
    queryParams.countryCode && selectedShortnames?.length > 0

  const { isLoading, data: diffData, mutate } = useDiffMutation()

  const { mutate: mutateGenerateCohort } = useGenerateCohortMutation()

  const [containerStyles, setContainerStyles] = useState({
    width: window.tnym.getWidth() - (isSidemenuShown ? SideMenu.width : 0),
    height: window.tnym.getHeight(),
  })

  const hasData = !!diffData.ids[0]

  const metrics = React.useMemo(
    () => (hasData ? diffData.data[diffData.ids[0]].ids : []),
    [diffData, hasData]
  )

  const params = React.useMemo(() => {
    const { endDate, timespan } = dateTypeToTimespan(queryParams) ?? {}

    return {
      ...queryParams,
      endDate,
      intervalType: Number(queryParams.intervalType),
      shortNames: selectedShortnames,
      timespan,
    }
  }, [queryParams, selectedShortnames])

  const onPressGenerateCohort = React.useCallback(() => {
    if (typeof params.cohort === 'undefined') {
      Alert.error('Please enter a cohort name')
    } else {
      const intervalString = snakeToCamelCase(queryParams.dateType ?? '')
      const timeString =
        queryParams.dateType === dateTypes.CUSTOM_DATE
          ? `from ${dayjs(queryParams.startDate).format(
              'DD.MM.YY'
            )} till ${dayjs(queryParams.endDate).format('DD.MM.YY')}`
          : `for the ${intervalString}`
      const content = `Do you want to regenerate all shortnames for the cohort ${queryParams.cohort} ${timeString} ?`

      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content,
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => {
          Alert.info(`Cohort ${params.cohort} job creation requested`)

          mutateGenerateCohort(params, {
            onSuccess: () =>
              Alert.success(`Cohort ${params.cohort} jobs created`),
          })
        },
      })
    }
  }, [mutateGenerateCohort, params])

  const onPressRefresh = useCallback(() => {
    if (!hasSetRequiredValues) {
      Alert.error('Please select at least one short name')
    } else {
      mutate(params)
    }
  }, [hasSetRequiredValues, mutate, params])

  const onChangeCompareKey = useCallback(
    (value) => {
      dispatch(changeCompareKey({ compareKey: value }))
    },
    [dispatch]
  )

  const onChangeAutoRefresh = useCallback((value) => {
    setIsAutoRefreshEnabled(value)
  })

  const headerTitle = useMemo(
    () =>
      [
        'Diff',
        queryParams.countryCode ? queryParams.countryCode.toUpperCase() : '',
        queryParams.cohorts
          ? queryParams.cohorts
              .split(',')
              .map((v) => v.trim())
              .join(', ')
          : '',
        queryParams.experimentId || '',
        '- Tellonym Modcp',
      ].join(' '),
    [queryParams.countryCode, queryParams.cohorts, queryParams.experimentId]
  )

  const onPressDownloadCsv = useCallback(() => {
    /**
     * Functions are not completely structure proof and access the data in an ideal scenario
     * so we wrap it in a try catch to prevent the app from crashing
     */
    try {
      const arrayData = transformDiffDataToArray(diffData)
      const csvData = arrayData.map((e) => e.join(',')).join('\n')

      downloadCsv(csvData)
    } catch (e) {
      console.error(e)
      Alert.error('Error while generating csv')
    }
  }, [diffData])

  useEffect(() => {
    setContainerStyles({
      width: window.tnym.getWidth() - (isSidemenuShown ? SideMenu.width : 0),
      height: window.tnym.getHeight(),
    })
  }, [isSidemenuShown])

  useEffect(() => {
    const timeoutAmount = getTimeoutAmount(diffData)

    if (!timeoutAmount && interval.current) {
      clearInterval(interval.current)
    } else if (timeoutAmount && isAutoRefreshEnabled) {
      interval.current = setInterval(() => {
        if (isAutoRefreshEnabled) {
          mutate(params)
        }
      }, timeoutAmount)
    }

    return () => {
      clearInterval(interval.current)
    }
  }, [diffData, isAutoRefreshEnabled])

  const headerData = React.useMemo(
    () =>
      getHeaderData({
        metrics,
        intervalType: queryParams.intervalType,
      }),
    [metrics, queryParams.intervalType]
  )

  return (
    <View
      style={[
        containerStyles,
        {
          backgroundColor: theme.colors.background,
        },
      ]}>
      <Helmet title={headerTitle} />
      <Header
        hasData={hasData}
        hasSetRequiredValues={hasSetRequiredValues}
        isLoading={isLoading}
        onChangeAutoRefresh={onChangeAutoRefresh}
        onChangeCompareKey={onChangeCompareKey}
        onPressDownloadCsv={onPressDownloadCsv}
        onPressGenerateCohort={onPressGenerateCohort}
        onPressRefresh={onPressRefresh}
      />
      <StatsTableCh
        hasGraph
        compareKey={compareKey}
        items={diffData}
        headerData={headerData}
        hasCopyButton
        isLoading={isLoading}
      />
    </View>
  )
}

export { PageChDiff }
