import { createStructuredSelector } from 'reselect'
import { name } from './constants'

export const getState = (state) => state[name]

export const getAccessToken = (state) => state[name].accessToken

export const getCaptcha = (state) => state[name].captcha

export const getCurrentAuthScreen = (state) => state[name].currentAuthScreen

export const getDeviceCheckToken = (state) => state[name].deviceCheckToken

export const getEmail = (state) => state[name].email

export const getHasAcceptedTerms = (state) => state[name].hasAcceptedTerms

export const getHasLoggedIn = (state) => state[name].hasLoggedIn

export const getHasParentalConsent = (state) => state[name].hasParentalConsent

export const getHasPromptedPushPermission = (state) =>
  state[name].hasPromptedPushPermission

export const getIsAuthConnectPhoneBusy = (state) =>
  state[name].isAuthConnectPhoneBusy

export const getIsBanned = (state) => state[name].isBanned

export const getIsBusy = (state) => state[name].isBusy

export const getIsEmailAvailable = (state) => state[name].isEmailAvailable

export const getIsUsernameAvailable = (state) => state[name].isUsernameAvailable

export const getIsValidLogin = (state) => state[name].isValidLogin

export const getLang = (state) => state[name].lang

export const getLoginFailureCount = (state) => state[name].loginFailureCount

export const getPassword = (state) => state[name].password

export const getIsRefreshingBannedStatus = (state) =>
  state[name].isRefreshingBannedStatus

export const getResetToken = (state) => state[name].resetToken

export const getService = (state) => state[name].service

export const getShouldShowAtt = (state) => state[name].shouldShowAtt

export const getShouldShowCmp = (state) => state[name].shouldShowCmp

export const getShouldShowGoogleIdentityServiceButton = (state) =>
  state[name].shouldShowGoogleIdentityServiceButton

export const getUserId = (state) => state[name].userId

export const getUsername = (state) => state[name].username

export const getLoginState = createStructuredSelector({
  accessToken: getAccessToken,
  lang: getLang,
  userId: getUserId,
})
