import { refreshSettings } from '@tellonym/core/profile/actions'
import { safeEffects } from '@tellonym/core/sagas'
import * as t from '@tellonym/core/user/types'
import { put } from 'redux-saga/effects'

const loginSuccess = function* () {
  yield put(refreshSettings())
}

export const actionHandler = {
  [t.LOGIN_SUCCESS]: [safeEffects.takeLatest, loginSuccess],
}
