import React from 'react'
import { useQueryParams } from '../common/hooks'
import { useShortnamesQuery } from './queries'

export const useSelectedShortnames = () => {
  const [shortnames, setShortnames] = React.useState([])
  const [queryParams] = useQueryParams()
  const { data: groups } = useShortnamesQuery()

  React.useEffect(() => {
    if (groups) {
      const newShortnames = new Set(Object.values(queryParams.shortNames ?? {}))

      for (const groupId of queryParams?.groups ?? []) {
        groups.data[groupId]?.shortNames?.forEach(({ shortName }) =>
          newShortnames.add(shortName)
        )
      }

      const shortnamesArray = Array.from(newShortnames)

      if (JSON.stringify(shortnamesArray) !== JSON.stringify(shortnames)) {
        setShortnames(shortnamesArray)
      }
    }
  }, [groups, queryParams, shortnames])

  return shortnames
}
