import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FlatList, Modal, Spinner, Text, theme, View } from '../../common'
import {
  fetchFollowers as fetch,
  refreshFollowers as refresh,
} from '../actions'
import { Followers as selector } from '../selectors'
import { FollowItem } from './FollowItem'

const mapStateToProps = (state) => ({
  ...selector(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ refresh, fetch }, dispatch),
})

class _Followers extends React.PureComponent {
  componentDidMount() {
    const { actions, userId } = this.props

    actions.refresh({ userId })
  }

  render() {
    const { actions, hasMore, isFetching, isRefreshing, data, userId } =
      this.props

    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Modal.Body>
          <View
            style={{
              borderRadius: 25,
              backgroundColor: theme.colors.background,
              padding: 32,
              width: 500,
              minHeight: 150,
              height: 550,
            }}>
            <Text type="h2" bold style={{ marginBottom: 12 }}>
              Followers
            </Text>
            {isRefreshing ? (
              <View style={{ alignItems: 'center', marginTop: 48 }}>
                <Spinner />
              </View>
            ) : (
              <View
                style={{
                  flex: 1,
                  alignItems: 'center',
                }}>
                <FlatList
                  actions={{
                    refresh: (payload) =>
                      actions.refresh({
                        userId,
                        ...payload,
                      }),
                    fetch: (payload) =>
                      actions.fetch({
                        userId,
                        ...payload,
                      }),
                  }}
                  component={FollowItem}
                  hasMore={hasMore}
                  isFetching={isFetching}
                  isRefreshing={isRefreshing}
                  items={data[userId] || { ids: [], data: {} }}
                  style={{ width: '100%' }}
                  contentContainerStyle={{
                    height: 460,
                    flex: undefined,
                  }}
                  extraData={{
                    modalId: this.props.modalId,
                  }}
                />
              </View>
            )}
          </View>
        </Modal.Body>
      </View>
    )
  }
}

const Followers = connect(mapStateToProps, mapDispatchToProps)(_Followers)

const show = (payload) =>
  Modal.show({
    render: (props) => <Followers {...payload} {...props} />,
    hasBackdrop: true,
    shouldHideOnBackgroundPress: true,
  })

export const ModalFollowers = { show }
